import React from "react";
import Captcha from "./Captcha";
import CommonDialogWrapper from "./CommonDialogWrapper";
import ChangePassword from './ChangePassword';
import Login from "./Login";
import ServerPost, { ServerResponseType } from "./ServerPost";
import GetIP from "./GetIP";
import md5 from "md5";
export default class ResetPassword extends React.Component {
    dialogWrapper = new CommonDialogWrapper(this);
    constructor(props) {
        super(props);
        this.state = {
            resetStep: 0
        }
    }
    componentDidMount = () => {
        this.dialogWrapper.showYesNoCancel("Reset Password", "Are you sure you want to reset your password?",
        (dialogResult)=>{
            if(dialogResult[0] === "YES") {
                this.setState({resetStep: 1});
            } else {
                this.props.onCancel();
            }
        });
    }
    validateEmail = async(guid) => {
        // connect to the server to verify the email hash is the same as on file
        // for the current user account...
        // generate a DUD token as data / not token
        var data = {
            user: this.props.context.user,
            part1: md5(this.state.userName),
            part2: "",
            part3: Date.now().toString(),
            part4: guid,
            part5: md5(await new GetIP().init(this.props.context.serverSettings.serverurl)),
            part6: ""
        }
        console.log(data);
        /**@type {ServerPost} */
        var sp = new ServerPost(this.props.context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("reset", data, false);
        if(response.type === ServerResponseType.success) {
            this.setState({resetStep: 3});
        } else {
            this.dialogWrapper.showAlert("Error", "There was a server error: " + response.message, this.props.onCancel);
            this.setState({resetStep: -1});
        }
    }
    render() {
        return (
            <>  
                {this.state.resetStep === -1 ? <div>There was an error, you will need to refresh and try again...</div>:<></>}
                {this.state.resetStep === 0 ? <div>Please wait...</div>: <></>}
                {this.state.resetStep === 1 ? 
                    <div>
                        <h2>Reset Password</h2>
                        <p>To begin, please enter your email to validate it:</p>
                        <input type="text" onChange={(e)=>{this.setState({userName:e.target.value})}} />
                        <p>When you click <b>Validate...</b> button your old password will be reset and a new password will be sent to your email:</p>
                        <button onClick={()=>this.setState({resetStep:2})}>Validate...</button>
                        <p>To complete changing your password:</p>
                        <ul>
                            <li>You will be required to validate you are human.</li>
                            <li>A new/temporary password will be sent tp your email address.</li>
                            <li>You will be prompted to log int again.</li>
                            <li>You will have to verify with two-factor again.</li>
                            <li>Then you will be allowed to change your password.</li>
                        </ul>
                    </div>
                : <> </>}
                {this.state.resetStep === 2 ?
                    <Captcha context={this.props.context} onConfirm={(guid)=>this.validateEmail(guid)} onFail={this.props.onCancel} />
                : <></> }
                {this.state.resetStep === 3 ?
                    <Login forceCaptcha={false} context={this.props.context} onLogin={()=>this.setState({resetStep:4})} onCancel={this.props.onCancel} />
                : <></> }
                {this.state.resetStep === 4 ?
                    <ChangePassword context={this.props.context} onComplete={()=>this.setState({resetStep:5})} />
                : <></> }
                {this.state.resetStep === 5 ?
                    <div>
                        You can now click Login from the menu, and use your new password.
                    </div>
                : <></> }
                {this.dialogWrapper.renderDialogElement()}
            </>
        );
    }
}