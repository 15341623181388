import ServerPost, {ServerResponseType} from "./ServerPost";
/**
 * {React.Class}
 * This class is used to load the user settings object via server request
 * NOTE: Caches the results as long as the user does not change
 */
export class UserSettings {
    constructor(userName, serverUrl) {
        this.state = {
            user: userName,
            server: serverUrl
        }
    }
    /**
     * Loads the settings and returns a settings object
     */
    load = async() => {
        var data = { user: this.state.user };
        /**@type {ServerPost} */
        var sp = new ServerPost({serverurl:this.state.server, user:this.state.user});
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("settings", data, true);
        if(response.type === ServerResponseType.success) {
            return response.message;
        } else {
            return "failed";
        }
    }
}
export default UserSettings;

/**
 * @typedef {Object} UserSettingsDef
 * @property {String} email
 * @property {Boolean} emailVerified
 * @property {String} emailPending
 * @property {FieldItem[]} fields
 * @property {MenuItem[]} topmenu
 * @property {StyleItem[]} styles
 */
/**
 * @typedef {Object} FieldItem
 * @property {String} name
 * @property {String} value
 * @property {Boolean} builtIn
 */
/**
 * @typedef {Object} MenuItem
 * @property {String} title
 * @property {String} path
 */
/**
 * @typedef {Object} StyleItem
 * @property {String} name
 * @property {String} value
 */