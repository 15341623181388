import React from 'react';
import './_default.css';
import { Emoji } from "./Emoji";
import EditorRibbon, { EditorRibbonTab } from './EditorRibbon';
export class EditorToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmojiForm: { "display": "none" },
            insertCallback: props.onAction,
            incomingCommand: props.sendCommand,
        };
        this.h1Button = React.createRef();
    }
    render() {
        if(this.props.sendCommand !== "") { 
            switch(this.props.sendCommand) {
                case "1": 
                    this.props.onAction("INSERT","# {value}");
                    break;
                case "2": 
                    this.props.onAction("INSERT", "## {value}");
                    break;
                case "3": 
                    this.props.onAction("INSERT", "### {value}");
                    break;
                case "b": 
                    this.props.onAction("INSERT", "**{value}**");
                    break;
                case "i":
                    this.props.onAction("INSERT", "*{value}*");
                    break; 
                default:
                    break;
            }
        }
        var thisObject = this; 
        var fileMenuLabel = this.props.getState("saved") === "hidden" ? <>File <span role="img" aria-label="icon" style={{fontSize:"6pt"}}>🔴</span></>: 
                                                                        <>File <span role="img" aria-label="icon" style={{fontSize:"6pt"}}>🟢</span></>;
        const mdTableTemplate = "^|  aaa  |  bbb  |  ccc  |  ddd  |\r\n" +
                                 "| ----- | :---- | ----: | :---: |\r\n" +
                                 "|   1   |   2   |   3   |   4   |";
        return (
            <div id="Ribbon">
                <EditorRibbon color={this.props.color} 
                        onTabSelection={(e,i)=>{this.props.onAction("TAB", e);}}
                        defaultTab={1}>
                    <EditorRibbonTab label={fileMenuLabel}>
                        <button onClick={(e) => { this.props.onAction("COMMAND", "save")} }><span role="img" aria-label="icon">💾</span> Save</button>
                        <button hidden={this.props.getState("delete")} onClick={(e) => { this.props.onAction("COMMAND", "delete")} }>
                            <span role="img" aria-label="icon">🗑️</span> Delete</button>
                        <button onClick={(e) => { this.props.onAction("COMMAND", "close")} }><span role="img" aria-label="icon">❌</span> Close</button> {' | '}
                        <span hidden={this.props.getState("built-in")}><span role="img" aria-label="icon">🧱</span> [built-in] | </span> 
                        <span hidden={this.props.getState("not-saved")}><span role="img" aria-label="icon">🔴</span> [not saved]</span> 
                        <span hidden={this.props.getState("saved")}><span role="img" aria-label="icon">🟢</span> [saved]</span>
                    </EditorRibbonTab>
                    <EditorRibbonTab label="Info"> 
                        <span>Title:</span><input id="title" type="text" defaultValue={this.props.getState("title")} 
                                                  disabled={this.props.getState("disabled")}
                                                  onChange={(event) => this.props.onAction("EVENT", { target: "title", value: event.target.value})} />
                        {this.props.getState("built-in") === "hidden" ? null : (
                            <>
                                {' | '}
                                <span>Published: </span><input id="publishCheckbox" type="checkbox" 
                                                       defaultChecked={this.props.getState("publish")} 
                                                       onChange={(event) => this.props.onAction("EVENT", {target: "publish", value: event.target.checked}) } /> 
                            </>)}
                        {' | '}
                        <span>{this.props.getState("size")}</span>
                    </EditorRibbonTab>
                    <EditorRibbonTab label="Format">
                        <button onClick={(e) => { this.props.onAction("INSERT", "**{value}**")} }>B</button>
                        <button onClick={(e) => { this.props.onAction("INSERT", "*{value}*")} }>I</button> 
                        <button onClick={(e) => { this.props.onAction("INSERT", "~~{value}~~")} }><span style={{textDecoration:"line-through"}}>S</span></button> 
                        {' | '}
                        <button onClick={(e) => { this.props.onAction("INSERT", "^# ")} }>H1</button>
                        <button onClick={(e) => { this.props.onAction("INSERT", "^## ")} }>H2</button>
                        <button onClick={(e) => { this.props.onAction("INSERT", "^### ")} }>H3</button> 
                        {' | '}
                        <button onClick={(e) => { this.props.onAction("INSERT", "^>")} }><span role="img" aria-label="icon">➡️</span> Indent</button>
                    </EditorRibbonTab>
                    <EditorRibbonTab label="Insert">
                        <button onClick={(e) => { this.props.onAction("INSERT", "[{value}](https://link.here)")} }><span role="img" aria-label="icon">🔗</span> Hyperlink</button> 
                        {' | '}
                        <button onClick={(e) => { this.props.onAction("INSERT", "![image](https://link.to.image.here)")} }><span role="img" aria-label="icon">🖼️</span> Linked Image</button>
                        <button onClick={(e) => { this.props.onAction("DIALOG", "![image]({value})")} }><span role="img" aria-label="icon">🖼️</span> Embedded Image</button> 
                        {' | '}
                        <button onClick={(e) => { this.props.onAction("INSERT", "^- {value}")} }><span role="img" aria-label="icon">✳️</span> Bullet</button>
                        <button onClick={(e) => { this.props.onAction("INSERT", "^1. {value}")} }><span role="img" aria-label="icon">#️⃣</span> Number</button> 
                        <button onClick={(e) => { this.props.onAction("INSERT", "^- [ ] ...\r\n- [x] ...")} }><span role="img" aria-label="icon">#️⃣</span> Checklist</button> 
                        {' | '}
                        <button onClick={(e) => { this.props.onAction("INSERT", mdTableTemplate)} }><span role="img" aria-label="icon">📅</span> Table</button>
                        <button onClick={(e) => { this.props.onAction("INSERT", "``` \r\n{value}\r\n```")} }><span role="img" aria-label="icon">📜</span> Code</button> 
                        {' | '}
                        <button onClick={(e) => {
                            if(this.state.showEmojiForm.display === "none") {
                                this.setState({"showEmojiForm": { 
                                    "position": "absolute",
                                    "display": "block",
                                }});
                            } else {
                                thisObject.setState({"showEmojiForm": {"display":"none"}});
                            }
                        }}><span role="img" aria-label='Insert an emoji'>😀</span> Emoji</button>
                    </EditorRibbonTab>
                    <EditorRibbonTab label="Preview"><></></EditorRibbonTab>
                </EditorRibbon>
                <div style={this.state.showEmojiForm}>
                    <Emoji emojiSelected={
                        function(emoji) { 
                            thisObject.props.onAction("INSERT",emoji.native);
                            thisObject.setState({"showEmojiForm": {"display":"none"}});
                        }
                    }></Emoji>
                </div>
            </div>
        );
    }
}
export default EditorToolbar;