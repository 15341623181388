import React from 'react';
import ServerPost, {ServerResponseType} from './ServerPost';
import CommonDialogWrapper from './CommonDialogWrapper';
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
/** @typedef {import('./ServerPost.js').ServerResponse} ServerResponse */
export class TwoFactor extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    componentDidMount() {
        this.confirmUser();
    }
    /**
     * Confirms the user is who they say they are by prompting for
     * Two Factor code via the dialog
     */
    confirmUser = async() => {
        /** @type {ServerPost} */
        var sp = new ServerPost(this.props.context);
        /** @type {ServerResponse} */
        var response = await sp.sendCommand("confirm", null, true);
        if(response.type === ServerResponseType.success) {
            if(this.props.confirmAndPrompt) {
                // now we prompt the user to enter the 2 factor password
                this.dialogWrapper.showFFA(this.confirm, this.cancel);
            } else {
                this.props.confirmed();
            }
        } else {
            this.dialogWrapper.showAlert("Error", "Two-factor authentication failed [0].", this.cancel);
        }
    }
    /**
     * The user cancelled the dialog
     */
    cancel = () => {
        if(this.props.cancelled) this.props.cancelled();
    }
    /**
     * Confirms the user input for the Two-Factor
     * @param {string[]} dlgResponse 
     */
    confirm = async(dlgResponse) => {
        /**@type {String} */
        var code = dlgResponse[0];
        code = code.toUpperCase().trim(); // must - because it comes in that way
        console.log(code);
        /**@type {ServerPost} */
        var sp = new ServerPost(this.props.context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("2fa", null, true, null, code);
        if(response.type === ServerResponseType.success) {
            this.props.confirmed();            
        } else {
            this.dialogWrapper.showAlert("Error","Two-factor authentication failed [1].");
            this.cancel();
        }
    }
    render() {
        return this.dialogWrapper.renderDialogElement();
    }
}
export default TwoFactor;