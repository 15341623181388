import React from 'react';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import { ChangeStyles } from './ChangeStyles';
import { ChangeFields } from './ChangeFields';
import ProfileInfo from './ProfileInfo';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
export class ChangeOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedField: "",
            selectedValue: "",
            selectedMenu:"",
            selectedMenuValue:"",
        }
    }
    /**@type {Context} */
    context = null;
    render() {
        if(this.context.getUserSetting === undefined) this.context = this.props.context;
        var menuWarning = <span><b>DANGER ZONE:</b> Changing these settings may break your site.</span>
        return (
            <div >
                <ProfileInfo context={this.context} hideUI={false} loadComplete={()=>{}}></ProfileInfo>
                <hr/>
                <ChangeEmail context={this.context}></ChangeEmail>
                <hr/>
                <ChangePassword context={this.context}></ChangePassword>
                <hr/>
                <ChangeStyles context={this.context}></ChangeStyles>
                <hr/>
                <ChangeFields context={this.context} field={this.context.userSettings.fields} heading="Fields"
                              description="These are the fields you can use in your blog posts, that can be used over and over. There are
                              several default values already provided."
                              onChange={()=>{}}></ChangeFields>
                <hr/>
                <ChangeFields context={this.context} field={this.context.userSettings.topmenu} heading="Menu"
                              description={menuWarning}></ChangeFields>
            </div>
        )
    }
 }
export default ChangeOptions;