/**
 * {React.Class}
 * This class is used to load the server settings directly
 * NOTE: Caches the results for the entire session
 */
export class ServerSettings {
    /**
     * Loads the settings and returns a settings object
     * @param {serverSettingsCallback} callback 
     */
    load = async() => {
        // check to see if we have an in memory copy already
        var sessionValue = sessionStorage.getItem("serverSettings");
        if(sessionValue != null) {
            return JSON.parse(sessionValue);
        }
        // if not load the file from the server
        const requestOptions = {
            method: 'GET'
        };
        // cs-spell:ignore serversettings
        var settingsJson = await fetch(window.origin + "/serversettings.json",requestOptions);
        const settingsObject = await settingsJson.json();
        sessionStorage.setItem("serverSettings", JSON.stringify(settingsObject)); // save
        return settingsObject;
    }
}
export default ServerSettings;