import React from 'react';
import ReactDOM from 'react-dom';
/**
 * This is the primary object to setup a dialog
 * @typedef {Object} DialogOptions
 * @property {String} heading
 * @property {String} description
 * @property {DialogField[]} dialogFields
 * @property {DialogButton[]} dialogButtons
 * @property {Number} top
 * @property {Number} left
 * @property {Number} height
 * @property {Number} width
 * @property {Boolean} modal
 */

/**
 * This is a dialog field for the dialog module
 * @typedef {Object} DialogField 
 * @property {string} type The type of field: text|password|color|etc.
 * @property {string} label The label on the field
 * @property {string} value The value for the dialog field
 * @property {Boolean} default Whether Enter/Escape activate default buttons
 * @property {string} defaultValue The default value to be in the field
 * @property {Boolean} required cannot OK until filled in
 * @property {String} accepts the type files it can accept of a file browser
*/
/**
 * This is a dialog field for the dialog module
 * @typedef {Object} DialogButton 
 * @property {string} label                     The label on the button
 * @property {DialogButton~callback} callback   The function to call on click
 * @property {Boolean} default                  If this is the default button {ENTER}
 * @property {Boolean} cancel                   If this is the cancel button {ESC}
 * @property {string} buttonValue               if pressed this is the value returned
*/
/**
 * This callback is displayed as part of the DialogButton class.
 * @callback DialogButton~callback
 * @property {DialogOptions} options
 * @property {Boolean} show
 */
export class Dialog extends React.Component {
    showRequired = false;
    cancelCallback = null;
    defaultCallback = null;
    fieldValues = [];
    el = [];
    portalRoot = [];
    constructor(props) {
        super(props);
        this.el = document.createElement("div");
        this.portalRoot = document.getElementById("portal");   
    }
    componentDidMount = () => {
        this.portalRoot.appendChild(this.el);
    };
    componentWillUnmount = () => {
        this.portalRoot.removeChild(this.el);
    };
    keyPress = (e) => {
        if(e.key === "Enter") {
            this.defaultCallback(this.fieldValues);
        }
        if(e.key === "Escape") {
            this.cancelCallback(this.fieldValues);
        }
    }
    inputStyle={
        width:"100%",
    }
    render() {
        // first -- if NO SHOW -- do nothing
        if(this.props.show === false || this.props.options === undefined || this.props.options === null) {
            this.showRequired = false;
            this.fieldValues = [];
            return <></>
        } 
        //
        // if we are here - render the dialog...
        //
        const fieldList = [];
        const buttonList = [];
        if(this.props.options.modal === undefined) this.props.options.modal = false;
        if(this.props.options.top !== undefined) document.documentElement.style.setProperty('--dialogTop', this.props.options.top);
        if(this.props.options.left !== undefined) document.documentElement.style.setProperty('--dialogLeft', this.props.options.left);
        if(this.props.options.width !== undefined) document.documentElement.style.setProperty('--dialogWidth', this.props.options.width);
        if(this.props.options.height !== undefined) document.documentElement.style.setProperty('--dialogHeight', this.props.options.height);
        if(this.props.options.dialogFields !== undefined) this.props.options.dialogFields.forEach(
            /**
             * 
             * @param {DialogField} e 
             * @param {Number} i 
             */
            (e,i) => {
            // cSpell:ignore tdleft tdright
            fieldList.push(                        
                <tr key={"d"+i}>
                    <td key={"tdleft"+i}>
                        {e.type === "link" ? <></> : <span key={"l"+i}>{e.label}</span> }
                    </td>  
                    <td key={"tdright"+i} colSpan="2">
                        {e.type === "link" ? (
                            <a href="/resetpassword" onClick={(evt)=>{evt.preventDefault(); e.value(); }}>{e.label}</a>
                        ) : ( 
                            <input id={"field"+i} 
                               name={"field"+i} 
                               key={"i"+i} 
                               type={e.type}
                               accept={e.accepts} 
                               defaultValue={e.value} 
                               onChange={(evt)=>{
                                if(e.type === "file") {
                                    this.fieldValues[i] = evt.target.files;
                                } else {
                                    this.fieldValues[i] = evt.target.value}
                                }} 
                               onKeyUp={(evt)=>e.default?this.keyPress(evt):{}}
                               style={this.inputStyle}/>
                        )}
                        {this.showRequired === true ? <span className="red"> *</span> : <></>}
                    </td>
                </tr>);
        });
        if(this.props.options.dialogButtons !== undefined) this.props.options.dialogButtons.forEach(
            /**
             * 
             * @param {DialogButton} e 
             * @param {Number} i 
             */
            (e,i) => {
                if(e.cancel) this.cancelCallback = e.callback;
                if(e.default) this.defaultCallback = e.callback;
                buttonList.push(                        
                    <td key={"td"+i} className="rightAlign">
                        <button key={"l"+i} 
                                onClick={()=>{
                                    var allow = true;
                                    if(e.cancel) {
                                        e.callback([]);
                                    } else {
                                        if(e.default) { // we have to make sure all the REQUIRED fields are filled in
                                            if(this.props.options.dialogFields.length > 0) {
                                                for(var i=0; i<this.props.options.dialogFields.length;i++) {
                                                    // is the field required and does it value a not-blank/not-null/not-undefined
                                                    if(this.props.options.dialogFields[i].required && 
                                                    (this.fieldValues[i] === "" || this.fieldValues[i] === null || this.fieldValues[i] === undefined)) allow = false;
                                                }
                                            }
                                        }
                                        // if there are no field values and the default button or any button other than the
                                        // the default is pressed, we return the request values for the button
                                        if(this.props.options.dialogFields === undefined) {
                                            this.fieldValues.push(e.buttonValue);
                                        }
                                        if(allow) e.callback(this.fieldValues) 
                                        else {
                                            this.showRequired=true;
                                            this.setState({});
                                        } 
                                    }
                                }}>{e.label}</button>
                    </td>  
                );
            });
        /** 
         * This is CRITICAL to get dialogs to appear and work correctly 
         * What we are doing is popping out the dialog and putting it on
         * top of ALL other windows by putting it into the root of the 
         * APP and then properly "FRAGMENTING" it in a "PORTAL" so that
         * it will not blow up the DOM:
         * https://css-tricks.com/using-react-portals-to-render-children-outside-the-dom-hierarchy/
        */
        return ReactDOM.createPortal(
            <div id="dialogRoot" key={new Date().getTime()} className={this.props.options.modal?"disablePage": ""}>
                <div className="floatingDialog">
                    <h3>{this.props.options.heading}</h3>
                    <span>{this.props.options.description}</span>
                    <br/>
                    <table style={this.inputStyle}>
                        <thead>{fieldList}</thead>
                        <tbody>
                            <tr><td>{'   '}</td><td><p>{'   '}</p></td></tr>
                            <tr><td>{this.showRequired ? <span className="red">* required</span> : <></>}</td>{buttonList}</tr>
                        </tbody>
                    </table>
                </div>
            </div>, this.el
        );
    }
}
export default Dialog;