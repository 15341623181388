import React from 'react';
import { CommonDialogWrapper } from './CommonDialogWrapper';
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./UserSettings').FieldItem } FieldItem */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
export class ChangeFields extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    constructor(props) {
        super(props);
        this.state = {
            updateButtonDisabled: true,
            selectedField:"",
            selectedValue:"",
            selectedBuiltIn:false
        }
    }
    /**
     * Updates the selected field
     */
    update = async() => {
        var thisRef = this;
        /**@type {FieldItem} */
        var item = this.props.field.filter(function(el){return el.name === thisRef.state.selectedField})[0];
        if(item === null) return;
        item.value = this.state.selectedValue;
        await this.props.context.save();
        this.props.context.appStateForceRefresh();
    }
    /**
     * Asks the user for the field name and value
     */
    add = async() => {
        this.dialogWrapper.showKeyValue("Add", "Specify the name of the and the value you want to add.",
                                        "",this.confirmAdd,()=>{},"Name", "Value");
    }
    /**
     * Verifies that the field does not already exist and if not, adds it
     * @param {string[]} dialogResult 
     * @returns 
     */
    confirmAdd = async(dialogResult) => {
        if(dialogResult === undefined || dialogResult.length <= 1) return;
        var fieldName = dialogResult[0];
        var fieldValue = dialogResult[1];
        var fFound = false;
        // see if we already have an item
        await this.props.field.forEach(
            /**
             * 
             * @param {FieldItem} item 
             * @param {Number} idx 
             */
            (item,idx)=> {
                if(item.name === fieldName) {
                    fFound = true;
                }
            }
        );
        if(fFound) {
            this.dialogWrapper.showAlert("Error", "The field " + fieldName + " already exists.");
            return;
        }
        // we are good if we are here, so we need to save the value
        // to the existing settings object and refresh the page state
        /**@type {FieldItem} */
        var newFieldItem = {};
        newFieldItem.builtIn = false;
        newFieldItem.name = fieldName;
        newFieldItem.value = fieldValue;
        this.props.field.push(newFieldItem);
        await this.props.context.save();
        this.props.context.appStateForceRefresh();
    }
    /**
     * Removes the selected field
     */
    remove = async() => {
        this.dialogWrapper.showYesNoCancel("Remove Field", 
            "Are you sure you want to remove the selected item?",
            /**
             * Users response
             * @param {string[]} dialogResult 
             * @returns 
             */
            async (dialogResult)=> {
                if(dialogResult[0].toLowerCase() !== "yes") return;
                var thisRef = this;
                /**@type {FieldItem} */
                var item = this.props.field.filter(function(el){return el.name === thisRef.state.selectedField})[0];
                if(item === null) return;
                this.props.field.pop(item);
                await this.props.context.save();
                this.props.context.appStateForceRefresh();
            });
    }
    render() {
        var fields = this.props.field;
        var fieldOptionHtml = Object.keys(fields).map((i)=><option key={i} built-in={fields[i].builtIn.toString()} value={fields[i].value}>{fields[i].name}</option>); 
        return (
            <div>
                <h3>{this.props.heading}</h3>
                <span>
                    {this.props.description}
                </span><br/><br/>
                <table>
                    <thead>
                        <tr>
                            <td className="wideTd">
                                Field Name:<br/>
                                <select id="from" className="wideTd scroll" onChange={(e)=>{
                                    this.setState({
                                                    selectedField: e.target.options[e.target.selectedIndex].innerText,
                                                    selectedValue: e.target.value,
                                                    updateButtonDisabled: true,
                                                    selectedBuiltIn:e.target.options[e.target.selectedIndex].getAttribute("built-in") === "true" ? true : false
                                                });
                                }}>
                                    <option></option>
                                    {fieldOptionHtml}
                                </select>{'   '}
                            </td>
                            <td className='alignTop'>
                                Field Value:<br/>
                                <input id="fieldValue" type="text" 
                                    defaultValue={this.state.selectedValue} 
                                    onChange={(e)=>{
                                        this.setState({"updateButtonDisabled": false,
                                                       "selectedValue":e.target.value});
                                    }}/> 
                                {'   '}
                                <button disabled={this.state.updateButtonDisabled} onClick={this.update}>Update</button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <button onClick={()=>this.add()}>Add Field</button>{'   '}
                                <button onClick={()=>this.remove()} disabled={this.state.selectedField==="" || this.state.selectedBuiltIn}>Remove Field</button>{'  '}
                                {this.state.selectedBuiltIn ? <span className="red">* built-in field, cannot remove</span> : <></>}
                            </td>
                        </tr>
                    </thead>
                </table>
                {this.dialogWrapper.renderDialogElement()}
            </div>
        );
    }
}