import React from 'react';
//import {Menu} from './Menu';
import Hamburger from './Hamburger';
import ServerPost, { ServerResponse, ServerResponseType } from './ServerPost';
import './_default.css';
import { ContextUserSettingType } from './AppContext.js';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
export class Header extends React.Component {
    /**
     * @constructor
     * @param {Object} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            emoji: ""
        }
    }
    componentDidMount = async() => {
        await this.getEmojiSvg();
    }
    menuButtonStyle = {
        float: "left"
    }
    headerStyle = {
        position: "relative",
        float: "right"
    }
    getEmojiSvg = async() => {
        const user = this.props.context.user;
        if(user === undefined || user === null || user === "") return;
        const svg = sessionStorage.getItem("svg_" + user);
        // have we already loaded this users svg url?
        if(svg === undefined || svg === null || svg === "") {
            /** @type {ServerPost} */ 
            const s = new ServerPost(this.props.context);
            /** @type {ServerResponse} */
            const response = await s.sendCommand("emoji", { user: user }, false);
            if(response.type === ServerResponseType.success) {
                this.setState({ emoji: response.message });
            } 
            // save it so we do not have to load it every time
            sessionStorage.setItem("svg_" + user, response.message);
        } else {
            // yes
            this.setState({ emoji: svg });
        }
    }
    render() {
        /**@type {Context} */
        const appContext = this.props.context;
        return (    
            <div className="Header">
                <div style={{display: "inline-block"}}>
                    <Hamburger context={this.props.context} />
                </div>
                <div style={{display: "inline-block"}}>
                    { // do we have an SVG icon to use for the EMOJI, or do we just have the emoji
                        (this.state.emoji === undefined || this.state.emoji === null || this.state.emoji === "") ?
                        <h1>{appContext.getUserSetting(ContextUserSettingType.fields, "iconemoji")}{' '}
                            {appContext.getUserSetting(ContextUserSettingType.fields, "title")}</h1> 
                    :
                      // here we have the SVG
                        <h1><img src={this.state.emoji} width="32" alt="user emoji" />{' '}
                        {appContext.getUserSetting(ContextUserSettingType.fields, "title")}</h1>
                    }
                </div>
                <hr/>
            </div>
        );
    }
}
export default Header;