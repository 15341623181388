import React from 'react';
import ServerPost, {ServerResponseType} from './ServerPost';
import { TwoFactor } from './TwoFactor';
import CommonDialogWrapper from './CommonDialogWrapper';
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
/** @typedef {import('./ServerPost.js').ServerResponse} ServerResponse */
export class ChangePassword extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    constructor(props) {
        super(props);
        this.state = {
            confirmationStep: 0
        }
        this.context = this.props.context;
    }
    /**@type {Context} */
    context = null;
    /**
     * Prompts the user to change the password
     */
    changePassword = async() => {
        this.dialogWrapper.showChangePassword(this.change, ()=>{this.setState( {confirmationStep: 0 })});
    }
    /**
     * Changes the password
     */
    change = async(dlgResponse) => {
        if(dlgResponse[0] === dlgResponse[1]) {
            var newPassword = dlgResponse[0];
            var complexity = this.complexityScore(newPassword);
            // next we verify the password meets complexity requirements
            if(complexity >= 4) {
                // confirm our 2fa code
                /**@type {ServerPost} */
                var sp = new ServerPost(this.props.context);
                /**@type {ServerResponse} */
                var response = await sp.sendCommand("change", null, true, null, newPassword);
                if(response.type === ServerResponseType.success) {
                    this.setState({ "confirmationStep": 3 });  
                    if(this.props.onComplete !== null) this.props.onComplete();         
                } else {     
                    this.dialogWrapper.showAlert("Error", "The password change failed.", 
                                                 ()=>this.setState({ "confirmationStep": 2 }));     
                }
            } else { 
                this.dialogWrapper.showAlert("Complexity", "The password is not " + 
                                             "complex enough [" + complexity + "].", 
                                             ()=>this.setState({ "confirmationStep": 2 }));
            }
        } else {      
            this.dialogWrapper.showAlert("Error", "The passwords do not match. Please correct.", 
                                         ()=>this.setState({ "confirmationStep": 2 }));
        }
    }
    complexityScore = (password) => {
        //Regular Expressions.
        var regex = [];
        regex.push(/[A-Z]/g); //Uppercase Alphabet.
        regex.push(/[a-z]/g); //Lowercase Alphabet.
        regex.push(/[0-9]/g); //Digit.
        //eslint-disable-next-line
        regex.push(/[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g); //Special Characters.
        var passed = 0;
        if(password.length >= 8) passed++;
        else return 0;
        //Validate for each Regular Expression.
        for (var i = 0; i < regex.length; i++) {
            if (new RegExp(regex[i]).test(password)) {
                passed++;
            }
        }
        return passed;
    }
    /**@type {DialogOptions} */
    dialogOptions = {};
    render() {
        switch(this.state.confirmationStep)
        {
            case 0:
                return (
                    <>
                        <h3>Password</h3>
                        <table>
                            <thead>
                                <tr>
                                    <td>
                        To reset your password, you must verify two-factor authentication first, then you
                        can change the password. To begin, press <b>Change...</b> below.
                        <br/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                        <button id="confirmButton" onClick={ () => this.setState({ confirmationStep: 1}) }>Change...</button>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </> );
            case 1: 
                return (<TwoFactor context={this.props.context} 
                                   confirmAndPrompt={true} 
                                   confirmed={()=>{this.setState( { confirmationStep: 2} )}}
                                   cancelled={()=>{this.setState( { confirmationStep: 0} )}}>
                                   </TwoFactor>);
            case 2:
                return (                    
                    <>
                        <span>You have been confirmed. Please click <b>Change Password...</b> to change your password.</span>
                        <br/><br/>
                        <button id="changePasswordButton" onClick={this.changePassword} disabled={this.state.changed}>Change Password...</button>
                        {this.dialogWrapper.renderDialogElement()}
                    </>);
            case 3:
                return (<><h3>Password</h3><p>You have successfully changed your password.</p></>);
            default: return (<></>);
        }
    }
}
export default ChangePassword;