import React from "react";
import { useNavigate } from "react-router-dom";
/** @typedef {import('./AppContext.js').Context} Context */
export default function Hamburger (props) {
    const nav = useNavigate();
    const [state, setState] = React.useState( {show:false, hover:false });
    const hamburgerMenuStyle = {
        width: "250px",
        height: "calc(100% - 120px)",
        top: "80px",
        left: "auto",
        position: "absolute",
        zIndex:9999999,
        backgroundColor:"var(--headerBgColor)",
        color:"var(--headerTextColor)",
        border: "3px double var(--headerTextColor)",
        padding: "5px",
    }
    const hamburgerButtonStyleDefault = {
        verticalAlign:"middle", 
        height:"30px",
        width:"40px", 
        paddingTop:"0px", 
        paddingBottom:"40px",  
        paddingLeft:"10px",     
        marginBottom:"0px", 
        fontSize:"30px",
        lineHeight:"5px",
        border:"none", 
        backgroundColor:"var(--bgColor)",       
        color:"var(--textColor)",
        cursor: "pointer",
        opacity: "0.9",
    }
    const hamburgerButtonStyleClicked = {
        verticalAlign:"middle", 
        height:"30px",
        width:"40px", 
        paddingTop:"0px", 
        paddingBottom:"40px",
        paddingLeft:"10px",         
        marginBottom:"0px", 
        fontSize:"30px",
        lineHeight:"5px",
        border:"none", 
        backgroundColor:"var(--textColor)",       
        color:"var(--bgColor)",
        cursor: "pointer",
    }
    const hamburgerHoverStyle = {
        verticalAlign:"middle", 
        height:"30px",
        width:"40px", 
        paddingTop:"0px", 
        paddingLeft:"10px",   
        paddingBottom:"40px",          
        marginBottom:"0px",
        fontSize:"30px",
        lineHeight:"5px",
        border:"none",
        backgroundColor:"var(--bgColor)",      
        color:"var(--textColor)",
        cursor: "pointer",
    }
    const navigate = (link) => {
        nav(link);
    }
    const menu = [];
    const user = props.context.user;
    const page = props.context.page;
    /**@type {Context} */
    var appContext = props.context;
    menu.push(<HamburgerButton key="home" visible={true} onClick={()=>{window.location = window.location.origin;}}>Back to nofrills home</HamburgerButton>);
    menu.push(<HamburgerButton key="div0-0" type="divider" visible={true} />);
    menu.push(<HamburgerButton key="label0" type="label" visible={true} label={"--[ " + user + "'s profile ]--"} />);
    menu.push(<HamburgerButton key="div0-1" type="divider" visible={true} />);
    appContext.userSettings.topmenu.forEach(
        /**
         * Iterate the top menu item in settings and create a menu
         * @param {*} item 
         * @param {Number} idx 
         */
        function(item, idx) {
            menu.push(<HamburgerButton key={idx} visible={true} onClick={()=>navigate(`/${user}${item.value}`)}>{item.name}</HamburgerButton>);
        }
    );
    // push the final items to the menu based on whether we have a possible
    // valid token or not... the key here is that it might not be valid
    // but still in the session storage, so we might fail trying to use it,
    // however, if it is there we will use that to show the extended menu 
    // items in the menu bar for the user account
    // cSpell:ignore signintoken signintokenguid
    var existingToken = sessionStorage.getItem("signintoken");
    var existingTokenGuid = sessionStorage.getItem("signintokenguid");
    var isLoginButtonVisible = existingToken === null || existingToken === "" || existingTokenGuid === null || existingTokenGuid === "";
    // build menu item
    menu.push(<HamburgerButton key="div1" type="divider" visible={true}></HamburgerButton>)
    menu.push(<HamburgerButton key="li1" visible={isLoginButtonVisible} onClick={()=>{navigate(`/${user}/login`)}}>Login</HamburgerButton>);
    var isEditButtonVisible = page !== undefined && page !== null && isLoginButtonVisible === false && 
                              props.context.pageTitle !== undefined && props.context.pageTitle !== null &&
                              props.context.pageTitle !== "";
    menu.push(<HamburgerButton key="li2" visible={isEditButtonVisible} onClick={()=>{navigate(`/${user}/${page}/edit`)}}>Edit <i>{props.context.pageTitle}</i></HamburgerButton>);
    // only these on login
    menu.push(<HamburgerButton key="li3" visible={!isLoginButtonVisible} onClick={()=>{navigate(`/${user}/settings`)}}>Settings</HamburgerButton>);
    menu.push(<HamburgerButton key="li4" visible={!isLoginButtonVisible} onClick={()=>{navigate(`/${user}/new`)}}>+New Post</HamburgerButton>);
    menu.push(<HamburgerButton key="div2" type="divider" visible={!isLoginButtonVisible}></HamburgerButton>)
    menu.push(<HamburgerButton key="li5" visible={!isLoginButtonVisible} onClick={()=>{navigate(`/${user}/logout`)}}>LogOut</HamburgerButton>);
    menu.push(<HamburgerButton key="div3" type="divider" visible={true}></HamburgerButton>)
    menu.push(<HamburgerButton key="li6" visible={isLoginButtonVisible} onClick={()=>{navigate(`/signup`)}}>Sign Up...</HamburgerButton>);
    var hamburgerButtonStyle = state.hover && !state.show ? hamburgerHoverStyle : state.show ? hamburgerButtonStyleClicked : hamburgerButtonStyleDefault;
    return (
        <div id="hamburgerMenuContainer">
            <div id="hamburgerButton" onClick={()=>setState({show:!state.show})} 
                                      onMouseEnter={()=>setState({hover:true, show:state.show})} 
                                      onMouseLeave={()=>setState({hover:false, show:state.show})}
                style={hamburgerButtonStyle}>
                _<br/>_<br/>_
            </div>
            {state.show === true ?
                <div id="hamburgerMenu" style={hamburgerMenuStyle}>
                    {menu}
                </div>
            : <></> }
        </div>
    );
}

export class HamburgerButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        }
    }
    buttonStyle = {
        position:"relative",
        width: "95%",
        backgroundColor:"var(--headerBgColor)",
        height: "20px",
        padding: "5px",
        fontSize: "12pt",
        margin:0,
        color:"var(--headerTextColor)",
    };
    hoverStyle = {
        position:"relative",
        width: "95%",
        backgroundColor:"var(--headerTextColor)",
        height: "20px",
        padding: "5px",
        margin:0,
        fontSize: "12pt",
        color:"var(--headerBgColor)" ,
    };
    render() {
        var myStyle = this.state.hover ? this.hoverStyle : this.buttonStyle;
        return(
            this.props.visible ? 
            this.props.type === "divider" ? <hr/> : this.props.type==="label" ? <h3>{this.props.label}</h3> :
            <div style={myStyle} onMouseEnter={()=>this.setState({hover: true})} onMouseLeave={()=>this.setState({hover:false})}
                 onClick={this.props.onClick}>
                {this.props.children}
            </div> : <></>
        );
    }
}