import React from 'react';
import EditorToolbar from "./EditorToolbar";
import { Buffer } from 'buffer';
import UndoStack from './UndoStack';
import CommonDialogWrapper from './CommonDialogWrapper';
import { Preformatter } from './Preformater';
import Login from './Login';
import './_default.css';
import ServerPost, { ServerResponseType } from './ServerPost';
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
/** @typedef {import('./ServerPost').BlogItem} BlogItem */
/** @typedef {import('./ServerPost').Mime } Mime */
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./ServerPost').ServerResponse} ServerResponse */
/** @typedef {import('./ServerPost').ServerResponseType} ServerResponseType */
/**
 * @type {UndoStack}
 */
var undoStack = null;
export class Editor extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    /**
     * @constructor
     * @param {Object} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            dirty: false,
            /**@type {BlogItem} */
            blogItem: {},
            toolbarCommand: null,
            preview: false,
            loggedIn:false,
        }
        this.editorRef = React.createRef();
    }
    /**
     * Checks to see of there is a saved session
     * @returns {BlogItem}
     */
    init = async() => {
        /**@type {BlogItem} */
        var item = localStorage.getItem("unsavedItem");
        if(item !== null) {
            item = JSON.parse(item);
            // notify the user and load the saved changes
            this.dialogWrapper.showAlert("Unsaved File",
                <span>You have unsaved changes from a previous session.<br/>
                      The following file with unsaved changes will be loaded:<br/>
                      <blockquote>{item.postName}</blockquote>
                      To exit and discard the changes, click <b>File</b>, then <b>Close</b>.</span>,
                ()=> {
                    this.setState({dirty:true}); 
                });
        } 
        return item;
    }
    /**
     * Creates a blank new post
     * @returns {BlogPost}
     */
    newPost = () => {
        /**@type {BlogItem} */
        var item = {};
        // create a default blank item
        item.builtIn = false;
        item.count = 0;
        item.createDate = new Date().getDate();
        item.fileName = "";
        item.mime = [];
        item.comments = [];
        item.modifiedDate = new Date().getDate();
        item.postBody = "";
        item.postName = "";
        item.visible = true;
        return item;
    }
    /**
     * Fires the first time the component mounts
     * Hook to the keydown event listeners
     */
    componentDidMount = async() => {
        document.removeEventListener('keydown', this.keypress);
        document.addEventListener('keydown', this.keypress);
        // first verify an item is not already loaded
        /**@type {BlogItem} */
        var item = await this.init();
        if(item === null && this.props.new !== undefined && this.props.new === true) {
            item = this.newPost();
            this.setState({blogItem: item}, ()=> {
                // setup the undo stack
                undoStack = new UndoStack(item.postBody);
            });
        } else if(item !== null) {
            this.setState({blogItem: item}, ()=> {
                // setup the undo stack
                undoStack = new UndoStack(item.postBody);
            });
        } else {
            await this.load(this.props.context.page);
            // setup the undo stack
            undoStack = new UndoStack(this.state.blogItem.postBody);
        }
    }
    /**
     * When a keypress occurs in the form we evaluate it here
     * @param {import('react').KeyboardEvent} e 
     * @returns 
     */
    keypress = (e) => {
        var thisRef = this;
        // User press CTRL+Z (undo)
        if(e.key === "z" && e.ctrlKey) {
            if(undoStack !== undefined && undoStack!==null) {
                var undo = undoStack.undo();
                /**@type {BlogItem} */
                var item = { ...this.state.blogItem };
                item.postBody = undo;
                if(undo != null) this.setState({blogItem: item});
                e.preventDefault();
                return false;
            }
        }
        // user pressed space or enter (capture an undo)
        if(e.key === " " || e.key === 'Enter') {
            if(undoStack !== undefined && undoStack!==null) {
                undoStack.push(this.state.blogItem.postBody);
                return true;
            }
        }
        // user pressed CTRL+S = save
        if(e.key === "s" && e.ctrlKey) {
            thisRef.onSaveClick(e);
            e.preventDefault();
            return false;
        }
        // user pressed CTRL+1 = H1
        if(e.key === "1" && e.ctrlKey && e.altKey) {
            thisRef.setState({"toolbarCommand":e.key});
            e.preventDefault();
            return false;
        }
        // user pressed CTRL+2 = H2
        if(e.key === "2" && e.ctrlKey && e.altKey) {
            thisRef.setState({"toolbarCommand":e.key});
            e.preventDefault();
            return false; 
        }
        // user pressed CTRL+3 = H3
        if(e.key === "3" && e.ctrlKey && e.altKey) {
            thisRef.setState({"toolbarCommand":e.key});
            e.preventDefault();
            return false;
        }
        // user pressed CTRL+B = Bold
        if(e.key === "b" && e.ctrlKey) {
            thisRef.setState({"toolbarCommand":e.key});
            e.preventDefault();
            return false;
        }
        // user pressed CTRL+i = Italics
        if(e.key === "i" && e.ctrlKey) {
            thisRef.setState({"toolbarCommand":e.key});
            e.preventDefault();
            return false;
        }
        // any other key - let the default happen
        return true;
    }

    /**
     * On edit event, the user wants to edit a file
     * @param {string} p - the page to open 
     */
    load = async(p) => {
        if(p !== undefined && p !== null && p !== "") {
            const data = {
                user: this.props.context.user,
                page: p,
                raw: false
            };
            /**@type {ServerPost} */
            var sp = new ServerPost(this.props.context);
            /**@type {ServerResponse} */
            var response = await sp.sendCommand("load", data, false);
            if(response.type === ServerResponseType.success) {
                /**{BlogItem} */
                var item = response.message;
                item.postName = Buffer.from(item.postName,'base64').toString('utf-8');
                item.postBody = Buffer.from(item.postBody,'base64').toString('utf-8');
                this.setState( { blogItem: item });
                // create the undo stack if it does not already exist
                if(undoStack == null) undoStack = new UndoStack(this.state.blogItem.postBody);
            } else {
                this.dialogWrapper.showAlert("Error", <span>Unable to read the file.</span>,
                () => {
                    this.goBack();
                });
            }
        }
    }
    /**
     * When the TextArea changes, we capture the change here and store it in
     * the local storage. We do this incase the user navigates away and comes
     * back, we can reload their state again
     * @param {string} value 
     */
    handleOnChange = value => {
        /**@type {BlogItem} */
        var item = { ...this.state.blogItem };
        item.postBody = value;
        this.updateItemState(item);
    }
    /**
     * The title bar changes - we capture the change here and store it in
     * the local storage. We do this incase the user navigates away and comes
     * back, we can reload their state again
     * @param {string} value 
     */
    handleTitleChange = value => {
        /**@type {BlogItem} */
        var item = {...this.state.blogItem };
        item.postName = value;
        this.updateItemState(item);
    }
    /**
     * Updates the state based on the checks item
     * @param {Boolean} value 
     */
    handleVisibleChange = value => {
        /**@type {BlogItem} */
        var item = {...this.state.blogItem };
        item.visible = value;
        this.updateItemState(item);
    }
    /**
     * Updates the session state and marks the file as dirty
     * @param {BlogItem} item 
     */
    updateItemState = async(item) => {
        this.setState({ blogItem: item, "dirty": true }, () => {
            localStorage.setItem("unsavedItem", JSON.stringify(item));
        });  
    }
    /**
     * Asks the user for an image and then opens it as a base 64 string
     * @param {String} value 
     */
    getFileFromUser = async(value) => {
        this.dialogWrapper.showFileBrowser("Select a File", 
            <span>Select an image file that is less than 50kb to open.</span>, 
            "image/*",
        async (dialogResults)=> {
            if(dialogResults === undefined || dialogResults === null || dialogResults.length === 0) return;
            /**@type {FileList} */
            var f = dialogResults[0];
            // verify that the file is noo too large and it of the proper type
            console.log(f[0]);
            if(f[0].size > 50000 || f[0].type.indexOf("image") < 0) { 
                this.dialogWrapper.showAlert("Error", "The image is too large or is not an image file.");
                return;
            }
            var item = { ...this.state.blogItem }
            /**@type {Mime} */
            var newMime = {};
            newMime.id = createUUID();
            newMime.data = await readFile(f[0]);
            // verify the user did not enter the same image again...
            for(/**@type {Mime} */ const existingMimeItem of item.mime) {
                if(existingMimeItem.data === newMime.data) {
                    // oops we found it, we will just insert a link to
                    // the existing mime id then
                    this.insertText(value, "EMBED:{" + existingMimeItem.id +"}");
                    return; // all done
                }
            }
            // if we are here - this image has not been inserted 
            // into this file before so we will write this to the mimes
            item.mime.push(newMime);
            this.insertText(value, "EMBED:{" + newMime.id +"}");
        })
    }
    /**
     * Inserts the text {value} into the document or places {data}
     * in teh value fields for images that are embedded
     * @param {String} value 
     * @param {String} data 
     */
    insertText = async(value, data) => {
        var start = this.editorRef.current.selectionStart;
        var finish = this.editorRef.current.selectionEnd;
        var newTextAreaValue = "";
        var selectedText = this.state.blogItem.postBody.substring(start, finish);
        var offset = 0;
        // means place it at the start of the line
        if(value.startsWith("^")) { 
            /**@type {String} */
            var text = this.editorRef.current.value;
            start--;
            while(text.charAt(start) !== "\n" && start >= 0) { start--; };
            start++;
            finish = start;
            selectedText = "";
            value = value.replace("^","");
        // normal replacement
        } else if(value.includes("{value}") && data === undefined) {
            var offSetStart = selectedText.length;
            value = value.replace("{value}", selectedText);
            offset = value.length - offSetStart;
        // image
        } else if(data !== undefined) {
            value = value.replace("{value}", data);
        }
        newTextAreaValue = this.state.blogItem.postBody.substring(0,start) + value + this.state.blogItem.postBody.substring(finish);
        var item = { ...this.state.blogItem };
        item.postBody = newTextAreaValue;
        await this.updateItemState(item);
        setSelectionRange(this.editorRef.current, finish + offset, finish + offset);
    }
    /**
     * The user clicked Cancel - go home if they agree
     * @param {Event} e 
     */
    onCancelClick = e => {
        if(this.state.dirty === false) {
            this.goBack();
            return;
        }
        this.dialogWrapper.showYesNoCancel("Close", 
            <span>Are you sure you want to close the editor?<br/>
                  You have unsaved changes.</span>,
            /**
             * Result from the dialog
             * @param {string[]} dialogResult 
             */
            (dialogResult) => {
                if(dialogResult[0] === "YES") {
                    localStorage.removeItem("unsavedItem")
                    this.goBack();
                }
            });
    }
    goBack = (forceHome) => {
        // figure out where we are...
        // if new -- go back to home
        // if editing an item -- go back to the item minus the edit button
        if(this.props.new) {
            window.location.replace(window.location.href.replace("/new",""));
        } else if(forceHome) {
            window.location.replace(window.location.origin + "/" + this.props.context.user);
        } else {
            window.location.replace(window.location.href.replace("/edit",""));
        }
    }
    /**
     * When the user clicks Save we save (or update) the post
     * to the server
     * @param {Event} e 
     */
    onSaveClick = async(e) => {
        //e.preventDefault();
        // make sure we have a title and a body
        if(this.state.blogItem.postBody === "" ||
           this.state.blogItem.postName === "") {
            this.dialogWrapper.showAlert("Notification", "You must have a title and text to save.");
            return;
        }
        // which command - save or update
        var item = { ...this.state.blogItem };
        var command = (item.fileName !== undefined && 
                       item.fileName !== null && 
                       item.fileName !== "") ? "update" : "save"
        item = this.cleanDeletedEmbeds(item); // clean-up items the user deleted
        // update for the server
        item.postBody = Buffer.from(item.postBody).toString("base64");
        item.postName = Buffer.from(item.postName).toString("base64");
        var sp = new ServerPost(this.props.context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand(command, item, true);
        if(response.type === ServerResponseType.success) {
            // fix the values back to their non-base64 values
            item.postBody = this.state.blogItem.postBody;
            item.postName = this.state.blogItem.postName;
            // update the filename
            item.fileName = response.message;
            // set the state back
            this.setState( {blogItem:item} );
            // clear the dirty flag and set the filename - so update/not save is next
            this.setState({dirty:false}, localStorage.removeItem("unsavedItem"));
        } else {
            // if the user hits the quota limit, or there is some type of server error
            this.dialogWrapper.showAlert("Error", <span>There was an error saving: <br/>
                                                        {response.message}</span>);
        }
    }
    /**
     * Removes MIME entries for items deleted from the body
     * @param {BlogItem} item 
     */
    cleanDeletedEmbeds = (item) => {
        // loop through the hidden mime collection for items and then
        // see if we can locate them in the body of the file
        /**@type {Mime[]} */
        var newMime = []
        for(var mimeCnt=0;mimeCnt<item.mime.length;mimeCnt++) {
            var pattern = "(EMBED:{" + item.mime[mimeCnt].id + "})";
            /**@type {RegExpMatchArray} */
            var instances = item.postBody.match(pattern);
            if(instances !== undefined && instances !== null && instances.length !== 0) {
                // it is still there, so keep it
                newMime.push(item.mime[mimeCnt]);
            }
        }
        // rebuild...
        item.mime = newMime;
        return item;
    }
    /**
     * User has selected to Delete the post
     */
    onDeleteClick = () => {
        this.dialogWrapper.showYesNoCancel("Delete", "Are you sure you want to delete this post?",
        /**
         * Result from the user being ashed
         * @param {String[]} dialogResult 
         */
        async(dialogResult)=> {
            if(dialogResult[0] !== "YES") return;
            var data = { fileName: this.state.blogItem.fileName };
            var sp = new ServerPost(this.props.context);
            /**@type {ServerResponse} */
            var result = await sp.sendCommand("delete", data, true);
            if(result.type === ServerResponseType.success) {
                localStorage.removeItem("unsavedItem");
                this.goBack(true);
            } else {
                this.dialogWrapper.showAlert("Error", "There was an error deleting: " + result.message);
            }
        });
    }
    /**
     * Called from the toolbar to get the state of certain controls
     * @param {String} e Name of the control 
     * @returns 
     */
    getState = (e) => {
        switch(e){
            case "delete": return this.state.blogItem.builtIn === false && (this.props.new === undefined || this.props.new === false) ? "" : "hidden";
            case "built-in": return this.state.blogItem.builtIn === true ? "" : "hidden";
            case "disabled": return this.state.blogItem.builtIn;
            case "saved": return this.state.dirty ? "hidden" : "";
            case "not-saved": return this.state.dirty ? "" : "hidden";
            case "title": return this.state.blogItem.postName;
            case "publish": return this.state.blogItem.visible;
            case "size":
                var size = JSON.stringify(this.state.blogItem).length / 1000;
                if(size < 1) return "<1kb";
                else return Math.round(size).toString() + "kb";
            default: return "";
        }
    }
    /**
     * Incoming command from the toolbar
     * @param {string} type
     * @param {string} value 
     */
     handleAction = (type, value) => {
        this.setState({ "toolbarCommand": null });
        // first determine the TYPE of insert:
        // MACRO
        // TEXT
        // DIALOG/FILE
        switch(type) {
            case "TAB":
                if(value === "Preview") {
                    this.setState({"preview": true});
                } else {
                    this.setState({"preview": false});
                }
                break;
            case "COMMAND":
                switch(value) {
                    case "save": this.onSaveClick(); break;
                    case "close": this.onCancelClick(); break;
                    case "delete": this.onDeleteClick(); break;
                    default: break;
                }
                break;
            case "INSERT":
                this.insertText(value);
                break;
            case "DIALOG":
                this.getFileFromUser(value);
                break;
            case "EVENT":
                switch(value.target) {
                    case "title":
                        this.handleTitleChange(value.value);
                        break;
                    case "publish":
                        this.handleVisibleChange(value.value);
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }
    /**
     * Primary Render function
     * @returns HTML
     */
    render() {
        return (
            this.state.loggedIn === false ? (
                <Login context={this.props.context} onLogin={()=>this.setState({loggedIn:true})} onCancel={()=>this.goBack()}/>
            ) : (
                <div>

                    <EditorToolbar color={this.props.context.getUserSetting("styles","bgColor")} 
                            sendCommand={this.state.toolbarCommand} 
                            onAction={this.handleAction} 
                            getState={this.getState} />
                    
                    { this.state.preview === false ? (                                           
                        <textarea id="editor" ref={this.editorRef} value={this.state.blogItem.postBody} onChange={(event) => this.handleOnChange(event.target.value)} /> 
                    ) : null }

                    { this.state.preview === true ? (
                        // It is IMPORTANT to make a copy of the blogItem here (...)
                        <div id="viewer"><Preformatter item={this.state.blogItem} context={this.props.context} /></div>
                    ) : null }

                    {this.dialogWrapper.renderDialogElement()}

                </div> )
        );
    }
}
export default Editor;
/**
 * Sets the range for the selection in the TextArea
 * @param {HTMLTextAreaElement} input 
 * @param {Number} selectionStart 
 * @param {Number} selectionEnd 
 */
function setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
}
/**
 * Reads a file from a FileList
 * @param {FileList} f 
 * @returns {String} Base64 String
 */
async function readFile(f) {
    var reader = new FileReader();
    const data = await new Promise((resolve, reject) => {
        reader.readAsDataURL(f);
        reader.onload = (e) => resolve(e.target.result);
        });
    return data;
}
/**
 * Returns a GUID
 * @returns {String}
 */
function createUUID() {
    // cSpell:ignore yxxx
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        //eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
 }