import React from "react";
import { Buffer } from 'buffer';
import ServerPost, { ServerResponseType } from './ServerPost';
/** @typedef {import('./ServerPost').ServerResponse} ServerResponse */
/** @typedef {import('./ServerPost').ServerResponseType} ServerResponseType */
export default class BlogStream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stream: []
        }
    }
    componentDidMount = async() => {
        /**@type {ServerPost} */
        var sp = new ServerPost({serverurl:this.props.context.serverSettings.serverurl, user:null});
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("stream", null, false);
        if(response.type === ServerResponseType.success) { 
            /** @type {StreamItem[]} */
            var data = JSON.parse(response.message);
            var streamItems = [];
            var i = 0;
            for(var item of data) {
                var ele = 
                    <div key={i++}>
                        <StreamTile item={item} onClick={this.props.onClickLink} />
                    </div>
                streamItems.push(ele);
            }
            this.setState({stream: streamItems});
        }       
    }
    render() {
        return (
            <>
                <h2>The Live Blog Stream</h2>
                {this.state.stream}
            </>
        );
    }
}
export class StreamItem {
    user = "";
    emoji = "";
    postName = "";
    postBody = "";
    modifiedDate = null;
    fileName = "";
}

export class StreamTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titleLinkStyle: this.onDefaultStyle,
            userLinkStyle: this.onDefaultStyle,
            itemStyle: this.defaultItemStyle,
        }
    }
    onHoverStyle = {
        textDecoration: "overline",
        cursor: "pointer",
    }
    onDefaultStyle = {
        textDecoration: "underline",
        cursor: "pointer",
    }
    defaultItemStyle = {
        border: "1px black solid",
        margin: "10px",
        padding: "10px",
    }
    itemHoverStyle = {
        border: "1px black inset",
        margin: "10px",
        padding: "10px",
        backgroundColor: "white",
        cursor: "pointer",
    }
    render() {
        return (
            <div style={this.state.itemStyle} onMouseEnter={()=>this.setState({itemStyle: this.itemHoverStyle})}
                                              onMouseLeave={()=>this.setState({itemStyle: this.defaultItemStyle})}
                                              onClick={()=>this.props.onClick(this.props.item.user, this.props.item.fileName)}>
                <span>
                    <b>{Buffer.from(this.props.item.postName,'base64').toString('utf-8')}</b>
                </span>, by 
                <span style={this.state.userLinkStyle} onMouseEnter={()=>this.setState({userLinkStyle: this.onHoverStyle})}
                                                      onMouseLeave={()=>this.setState({userLinkStyle: this.onDefaultStyle})}
                                                      onClick={()=>this.props.onClick(this.props.item.user, null)}> 
                    {' '}{this.props.item.emoji}{' '}{this.props.item.user}
                </span>
                <br/>
                <div><b>Posted on: </b>{this.props.item.modifiedDate}</div>
                <div><i>{Buffer.from(this.props.item.postBody,'base64').toString('utf-8')}</i></div>
            </div>
        );

    }
}

// style={this.state.titleLinkStyle}     onMouseEnter={()=>this.setState({titleLinkStyle: this.onHoverStyle})}
//                                                        onMouseLeave={()=>this.setState({titleLinkStyle: this.onDefaultStyle})}>