
import React from "react";
import ServerPost, {ServerResponseType} from './ServerPost';
export default class Captcha extends React.Component {
    item = {};
    failCnt = 0;
    timeLeft=25 // seconds
    emojis = [];
    challenge = "";
    key = "";
    componentDidMount = async() => {
        var context = this.props.context;
        if(this.props.context.user === undefined || this.props.context.user === null || this.props.context.user === "") {
            context = {"serverurl" : this.props.context.serverSettings.serverurl, "user": null };
        }
        /**@type {ServerPost} */
        var sp = new ServerPost(context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("captcha", null, false);
        if(response.type === ServerResponseType.success) {
            // we have two items in the message:
            // array: 1, 2, 3, 4, 5, 6... list of emojis
            // challenge: "dog" ... what the user is to click
            // key: {important ... return to the server}
            this.emojis = response.message.array;
            this.challenge = response.message.challenge;
            this.key = response.message.key;
            this.setup(); // now setup the form
        }
    }
    sourceDivStyle = {
        border: "1px white solid",
        float: "left",
        width: "160px",
        height: "160px",
        display: "grid",
        gridTemplateColumns: "auto auto auto auto"
    }
    destDivStyle = {
        border: "1px white solid",
        float: "right",
        width: "160px",
        height: "160px",
        backgroundColor: "gray",
    }
    parentDivStyle = {
        width: "350px",
        height: "250px"
    }
    emojiStyle = {
        display:"table-cell",    
        width: "40px",
        height: "40px",
        fontSize: "16pt",
        textAlign: "center",
        verticalAlign: "middle",
        backgroundColor:"white",
        cursor:"pointer",
        color: "black",
    }
    spinner = {
        margin: "auto",
        width: "20px",
        height: "20px",
        border: "none",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }
    floatingDialogStyle = {
        // margin: "auto",
        position: "absolute",
        left: 0,
        top: 0,
        bottom:0,
        right:0,
        width: "350px",
        height: "290px", 
        border: "2px green solid",
        backgroundColor: "black",
        padding: "10px",
        color: "white",
        zIndex: 99999999
    }
    dimPageStyle = {
        position: "absolute",
        width: "100%",
        height: "100%",
        left:0,
        top:0,
        backgroundColor: "black",
        opacity: "50%"
    }
    drag = (e) => {
        e.dataTransfer.setData("icon", e.target.id);
    }
    /**
     * We verify the emoji the user selected with the one
     * that is in the ANSWER file sent to us. The server
     * is stateless so we return the answer with the user
     * selection to let the server verify it
     * @param {String} emoji 
     * @returns {String} The guid from the server
     */
    confirmUserSelection = async(emoji) => {
        var data = {
            user: this.props.context.user,
            key: this.key,
            answer: emoji,
            nouser: this.props.noUser === undefined || this.props.noUser === null ? false : this.props.noUser,
        }
        var context = this.props.context;
        if(this.props.context.user === undefined || this.props.context.user === null || this.props.context.user === "") {
            context = {"serverurl" : this.props.context.serverSettings.serverurl, "user": null };
        }
        /**@type {ServerPost} */
        var sp = new ServerPost(context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("ack", data, false);
        if(response.type === ServerResponseType.success) {
            this.props.onConfirm(response.message); // a guid, or a guid/guidChallenge pair (nouser)
            return true;
        } else {
            return false;
        }
    }
    drop = async(e) => {
        e.preventDefault();
        // disabled the items
        var elems = document.querySelectorAll("div");
        for(const elem of elems) {
            if(elem.draggable !== undefined) elem.draggable = false;
            if(elem.id==="parent") { elem.disabled = true; elem.style.opacity = "50%"; }
        }
        var data = e.dataTransfer.getData("icon");
        e.target.appendChild(document.getElementById(data));
        if(!this.confirmUserSelection(document.getElementById(data).innerText)) {
            // user guessed wrong -- hang here for a second
            // and then increment fail count and try again
            window.setTimeout(()=> {
                this.failCnt++;
                if(this.failCnt >= 3) {
                    this.props.onFail();
                    return;
                }
                // verify not in a timeout/failed state first
                var timerElem = document.getElementById("counter");
                if(timerElem !== undefined && timerElem !== null) {
                    this.setup();
                    this.forceUpdate();
                }
                return;
            }, 1500);
        }
    }
    setup() {
        document.getElementById("itemName").innerHTML = this.challenge;
        var counterTimer = window.setInterval(()=> {
            // update the timer
            var timerElem = document.getElementById("counter");
            if(timerElem === undefined || timerElem == null) {
                window.clearInterval(counterTimer);
                return;
            }
            var template = "{#} second";
            timerElem.innerHTML = template.replace("{#}", this.timeLeft);
            // counter
            if(this.timeLeft !== 1) {
                timerElem.innerText += "s";
            }
            // if here and ZERO - user failed
            if(this.timeLeft < 0) {
                window.clearInterval(counterTimer);
                this.props.onFail();
                return;
            }
            this.timeLeft--;
        }, 1000);
        this.setState({});
    }
    render() {
        return (
            <>
                <div style={this.dimPageStyle}></div>
                <div style={this.floatingDialogStyle} key={new Date().getTime()}>
                    <h3>Verification</h3>
                    <p>Please drag the <span style={{fontWeight:"bolder"}} id="itemName">{this.challenge}</span> to the gray square.<br/>
                    Time remaining: <span id="counter">{this.timeLeft} seconds</span></p>
                    <div id="parent">
                        <div style={this.sourceDivStyle} id="source">
                            {this.emojis.map((item, index) => {
                                return (
                                    <div key={"d" + index} id={"id" + index} style={this.emojiStyle} draggable="true" onDragStart={this.drag}>
                                        <span key={"s" + index} role="img" aria-label="icon">{item}</span>
                                    </div>
                                );
                            })}
                        </div>
                        <div id="dest" style={this.destDivStyle} onDrop={this.drop} onDragOver={(e)=>e.preventDefault()}></div>
                    </div>
                </div>  
            </>
        )
    }
}