import React from "react";
import './_default.css';
import CommonDialogWrapper from "./CommonDialogWrapper";
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
export class LogOut extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    componentDidMount=()=>{
        this.dialogWrapper.showYesNoCancel("Log Out", "Are you sure you want to log out?", this.close, this.close);
    }
    close = (result) => {
        if(result !== undefined && result.length > 0 && result[0] === "YES") {
            // cs-spell:ignore signintoken signintokenguid
            sessionStorage.removeItem("signintoken");
            sessionStorage.removeItem("signintokenguid");
            sessionStorage.removeItem("loggedInAs");
            window.location = window.location.origin; // refresh
        } else {
            window.history.back();
            if(this.props.onCancel !== undefined) this.props.onCancel();
        }
    }
    render() {   
        return(
            this.dialogWrapper.renderDialogElement()       
        );
    }
}
export default LogOut;