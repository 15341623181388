import GetIP from './GetIP';
import md5 from 'md5';
/** @typedef {import('./AppContext.js').Context} Context */
export const ServerResponseType = {
    success: "success",
    fail: "fail",
    error: "error",
    unknown: "unknown"
}
export class ServerResponse {
    /**@type {ServerResponseType} */
    type = ServerResponseType.unknown
    /**@type {String} */
    message= ""
}
export class ServerPost {
    #serverUrl = "";
    #user = "";
    #ip = "";
    /**
     * Constructor to load the incoming settings
     * @param {Context} context 
     */
    constructor(context) {
        if(context.userSettings === undefined || context.userSettings === null) {
            // submitted as {serverurl, user}
            this.#serverUrl = context.serverurl;
            this.#user = context.user;
        } else {
            this.#serverUrl = context.serverSettings.serverurl;
            this.#user = context.user;
        }
    }
    /**
     * Primary entry point to accessing the server with a single command
     * supported in the list below and data, or NULL if not needed. Supports
     * commands that need authorization and those that do not.
     * NOTE: Authentication occurs on the server. If no bearer token is
     *       provided and one is required, there will be an error on response
     * @param {String} command The command to execute on the server.
     *                         Supported: save, update, list, settings, delete,
     *                                    savesettings, load, 2fa, confirm, change,
     *                                    newemail, auth, ack, login
     * @param {Object} data The data to send to the server
     * @param {Boolean} needsAuthentication If the command needs to sign in - true
     *                                      If undefined it will be set to true
     * @param {String} [username] (optional) Needed for certain commands, like login (default == "")
     * @param {String} [password] (optional) Needed for login or change password (default == "")
     * @param {Boolean} [dontHashUserName] (optional) For commands like changing the email we 
     *                                   need this to TRUE (FALSE by default or 
     *                                   if undefined)
     * @param {String} [guid] (optional) Needed for login command
     * @returns {ServerResponse}
     */
    sendCommand = async(command, data, needsAuthentication, username, password, dontHashUserName, guid) => {
        // set all defaults for optional params
        if(username === undefined || username === null) username = null;
        if(password === undefined || password === null) password = null;
        if(guid === undefined || guid == null) guid = null;
        if(needsAuthentication === undefined || needsAuthentication === null) needsAuthentication = true; // default
        if(dontHashUserName === undefined || dontHashUserName === null) dontHashUserName = false; // default
        await this.#getip();
        var token = null;
        if(needsAuthentication && guid === null) {
            // cs-spell:ignore signintoken signintokenguid myip nohash
            var sessionToken = sessionStorage.getItem("signintoken");
            var sessionGuid = sessionStorage.getItem("signintokenguid");
            // create a standard token -- from memory
            token = this.#createToken(username,password,sessionGuid,sessionToken, dontHashUserName);
        } else if(needsAuthentication && guid !== null) {
            // create a token for login -- using guid
            token = this.#createToken(username,password,guid, null, false);;
        }
        const requestOptions = this.#createPostRequestObject(command,token,data);
        var response = new ServerResponse();
        response = await this.#postToServer(requestOptions);
        return response;
    }

    /**
     * gets the IP address -- or loads from memory
     */
    #getip = async() => {
        var myip = sessionStorage.getItem("myip");
        if(myip === undefined || myip === null || myip === "") {
            myip = await new GetIP().init(this.#serverUrl);
            sessionStorage.setItem("myip", myip);
        }
        this.#ip = myip;
    }
    /**
     * Given the command and the data a POST request object is created
     * that is formatted for the server
     * @param {string} command 
     * @param {object} data 
     * @returns 
     */
    #createPostRequestObject = (command, token, data) => {
        const PostRequest = {
            "command": command,
            "token": token === undefined ? "" : token,
            "data": data === undefined ? "" : data
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(PostRequest)
        };
        return requestOptions;
    }
    /**
     * Creates a JSON token based on the information provided
     * @param {string} username 
     * @param {string} password 
     * @param {string} guid 
     * @param {string} existingToken 
     * @param {boolean} nohash if true, will not hash the email
     * @returns {JSON}
     */
    #createToken = (username, password, guid, existingToken, nohash) => {
        nohash = nohash === undefined ? nohash = false : nohash;
        const token = {
            user: this.#user,
            part1: nohash ? username : username !== null ? md5(username) : null,
            part2: password !== null ? md5(password): null,
            part3: Date.now().toString(),
            part4: guid != null ? guid : null,
            part5: md5(this.#ip),
            part6: existingToken
        }
        return token;
    }
    /**
     * Makes a post tot he server and returns the response formatted
     * as a JSON object
     * @param {object} requestOptions 
     * @returns {object}
     */
    #postToServer = async(requestOptions) => {
        try {
            var data = await fetch(this.#serverUrl, requestOptions);
            const response = await data.json();
            return response;
        } catch(e) {
            return "error: " + e.toString();
        }
    }
} 
export default ServerPost;

/**
 * @typedef BlogItem
 * @property {Boolean} builtIn
 * @property {string} fileName
 * @property {string} postName
 * @property {string} postBody
 * @property {Date} createDate
 * @property {Date} modifiedDate
 * @property {Boolean} visible
 * @property {Number} count
 * @property {Mime[]} mime
 * @property {Comment[]} comments
 */
/**
 * @typedef Comment
 * @property {String} commentId 
 * @property {String} commentUserName 
 * @property {String} commentBody
 * @property {Date} commentDate
 * @property {Boolean} commentApproved 
 * @property {Comment[]} commentComments
 */
/**
 * @typedef Mime
 * @property {string} id
 * @property {string} data
 */
/**
 * @typedef BlogListItem
 * @property {String} fileName
 * @property {String} postName As Base64
 * @property {Date} createDate
 * @property {Date} modifiedDate
 * @property {Boolean} visible
 */