import React from "react";
import ServerPost, {ServerResponseType} from './ServerPost';
import CommonDialogWrapper from "./CommonDialogWrapper";
export default class ProfileInfo extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    constructor(props) {
        super(props);
        this.state = {
            size: 0,
            max: 0,
            count: 0,
            loaded: false
        }
    }
    componentDidMount = async() => {
        var data = { "user" : this.props.context.user };
        /**@type {ServerPost} */
        var sp = new ServerPost(this.props.context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("profile", data, false);
        if(response.type === ServerResponseType.success) {
            var profileInfo = response.message;
            var size = profileInfo.size > 1000000 ? (Math.round((parseInt(profileInfo.size) / 1000000))).toString() + "MB" 
                                                  : (Math.round((parseInt(profileInfo.size) / 1000))).toString() + "kb";
            var max = (parseInt(profileInfo.max) / 1000000).toString() + "MB";
            this.setState( { size: size, max: max, count: profileInfo.count, loaded: true });
            if(profileInfo.message !== undefined && profileInfo.message !== null && profileInfo.message !== "") {
                this.dialogWrapper.showAlert("Profile Information", <span>{profileInfo.message}</span>,
                                                ()=>this.props.loadComplete?this.props.loadComplete(): null);
            } else {
                if(this.props.loadComplete) this.props.loadComplete();   
            }
        }
    }
    render() {
        if(this.state.loaded === false) return <></>;
        else return(
            <>
                {this.props.hideUI !== undefined || this.props.hideUI === false ?
                <>
                    <p><b>Blog Path</b>: {window.location.origin + "/" + this.props.context.user}<br/>
                       <b>Blog Size</b>: {this.state.size} of {this.state.max}<br/>
                       <b>Total Posts</b>: {this.state.count}</p>
                </>
                :<></>}
                {this.dialogWrapper.renderDialogElement()}
            </>
        );
    }
}