import React from 'react';
import { Preformatter } from './Preformater';
import './_default.css';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./ServerPost').BlogItem} BlogItem */
export class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: ""
        }
    }
    /**@type {BlogItem} */
    searchBodyItem = { postBody: "##MACRO:{listall}##" }; // initial value to search all
    buildArticles = async(search) => {
        this.searchBodyItem = {};
        this.searchBodyItem.postBody = "##MACRO:{list(" + search + ")}##";
        this.setState({});
    }
    render() {
        return (    
            <>
            <div>
                <label>Search:</label>
                <input name="blogSearch" type="text" 
                    onKeyPress={(e)=>{
                        if(e.key==="Enter") {
                            this.buildArticles(this.state.searchValue);
                        }}} 
                    onChange={(e)=> {
                        this.setState({"searchValue": e.target.value});
                    }} 
                />
                <button onClick={(e)=>{
                        this.buildArticles(this.state.searchValue);
                    }}>Search</button>
            </div>
            <hr/>
            <Preformatter key={new Date().getTime()} context={this.props.context} item={this.searchBodyItem} />
            </>
        );
    }
}
export default Search;