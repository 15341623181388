import React from 'react';
import { Buffer } from 'buffer';
import { Preformatter } from './Preformater';
import { Error404 } from './Error404';
import ServerPost, { ServerResponseType } from './ServerPost.js';
import NavButtons from './NavButtons';
import { ContextUserSettingType } from './AppContext';
/** @typedef {import('./ServerPost.js').ServerResponse} ServerResponse */
/** @typedef {import('./ServerPost.js').BlogItem} BlogItem */
export class Canvas extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = null;
    constructor(props) {
		super(props);
		this.state = {
			postItem: "",
            error: false
        };
	}
	async componentDidMount() {
        var thisRef = this;
        var page = this.props.context.page;
        var user = this.props.context.user;
        if(user === undefined || user === null || user === "") { 
            window.location.replace("https://blog.kryl.com");
            return;
        } else if(page === undefined || page === null || page === "" || page === "undefined" ) {
            page = "home";
        }
        try {
            const data = {
                user: user,
                page: page,
                raw: false
            };
            /**@type {ServerPost} */
            var sp = new ServerPost(this.props.context);
            /**@type {ServerResponse} */
            var response = await sp.sendCommand("load", data, false);
            if(response.type !== ServerResponseType.success) {
                thisRef.setState({error:true});
                return;
            }
            /**@type {BlogItem} */
            var item = response.message;
            if(item === undefined || item === null) {
                thisRef.setState({error:true});
            } else if(item.builtIn === true) {
                item.postBody = Buffer.from(item.postBody, 'base64').toString('utf-8');
                item.postName = Buffer.from(item.postName, 'base64').toString('utf-8');
                this.props.context.pageTitle = item.postName; // for hamburger menu                      
            } else {
                item.postBody = Buffer.from(item.postBody, 'base64').toString('utf-8');
                item.postName = Buffer.from(item.postName, 'base64').toString('utf-8');
                // for blog posts we show more information lie create, modified and count
                item.postBody = //"# " + item.postName + "\r\n\r\n---\r\n\r\n" +
                                "*visits*: " + item.count.toString() + ", " +
                                "*created*: " + new Date(item.createDate).toLocaleDateString() +", " + 
                                "*modified*: " + new Date(item.modifiedDate).toLocaleDateString() + "\r\n\r\n---\r\n\r\n" +
                                item.postBody;
                this.props.context.pageTitle = item.postName; // for hamburger menu
            } 
            thisRef.setState({postItem:item});
        } catch {
            thisRef.setState({error:true});
        }
	}
    render() {
        /** @type {import ("./AppContext").Context} */
        try {
            const context = this.props.context;
            const title = context.getUserSetting(ContextUserSettingType.fields, "title");
            document.getElementsByTagName("title")[0].innerText = title ? title : "No Title";
        } catch (ex) { console.log(ex); }
        return (
            this.state.error ? (
                <Error404 page={this.props.page} user={this.props.user} />
            ) : (
                <>
                    <NavButtons context={this.props.context} title={this.state.postItem.postName}></NavButtons>
                    <Preformatter key={new Date().getTime()} context={this.props.context} item={this.state.postItem} />
                </>
            )
        );
    }
}

export default Canvas;