import React from "react";
import BlogStream from "./BlogStream";
import './_default.css';
export class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showIntro: true,
            loggedInAs: "",
        }
    }
    componentDidMount = () => {
        var show = sessionStorage.getItem("showIntro");
        if(show === undefined || show === null || show === "") show = true;
        if(show === "false") show = false;
        this.setState({showIntro: show, loggedInAs: sessionStorage.getItem("loggedInAs")});
    }
    hide = (e)=> {
        sessionStorage.setItem("showIntro", false);
        this.setState({showIntro:false});
    }
    show = (e)=> {
        this.setState({showIntro:true});
    }
    render() {
        return(<>
            <h3>Welcome to the nofrills blog platform...</h3>
            <div>
            {this.state.showIntro ?
                <>
                    <hr/>
                    <div style={{right:0, position: "relative", float: "right"}}><button onClick={this.hide}>^</button></div>
                    {this.state.loggedInAs === undefined || this.state.loggedInAs === null || this.loggedInAs === "" ?
                        <>
                            <p>Where blogging is simple and your posts are shared in a stream of conscience.</p>
                            <p>To get started click the "Get Started" button below or if you have an account already, click "Log In":</p>
                            <button onClick={(e)=>window.location.href = "./signup"}>Get Started</button>{'   '}
                            <button onClick={(e)=>window.location.href = "./login"}>Log In</button>
                        </>
                    :
                        <><div>You are logged in. Click "Go" to view your profile...</div><button onClick={()=>this.props.onClickLink(this.state.loggedInAs)}>Go</button></> 
                    }
                </> 
            : 
                <div style={{right:0, position: "relative", float: "right"}}><button onClick={this.show}>v</button></div> 
            }
            </div>
            <hr/>
            <BlogStream context={this.props.context} onClickLink={this.props.onClickLink}></BlogStream>
        </>);
    }
}
export default Start;