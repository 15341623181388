import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page } from './Page';
import Error404 from './Error404';
import { Start } from './Start';
import {Context } from './AppContext';
import Editor from './Editor';
import Canvas from './Canvas';
import Login from './Login';
import LogOut from './LogOut';
import ChangeOptions from './ChangeOptions';
import Search from './Search';
import NewUser from './NewUser';
import './_default.css';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
/**
 * {React.Component}
 * This is the primary entry point for the blog platform
 */
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      default: false
    }
  }
  componentDidMount() {
    var thisRef = this;
    /**@type {Context} */
    this.contextObject = new Context(thisRef);
    this.contextObject.appStateForceRefresh = function() {thisRef.setState({});}
    this.contextObject.appStateForceRefresh();
    this.contextObject.init(()=> {
      if(this.contextObject.user !== null) this.setState( {loaded : true });
      else this.setState( {default : true});
    });
  }
  /**@type {Context} */
  contextObject = null;
  logonComplete = async(e) => {
    if(e === undefined || e === null || e === "") {
      // redirect to the root page
      window.location = window.location.href.replace("login", "");
    } else {
      // load user settings...
      await this.contextObject.loadUserSettings(e, true);
      window.location = window.location.href.replace("login", e);
    }
  }
 /**
   * This is the primary render function for the App React.Component
   * @returns {html}
   */
  render() {
    return (
        <>
          <div id="portal"></div>
          {this.state.loaded ? (
            <div className="App" id="main" key={new Date().getTime()}>
              <BrowserRouter>
                <Routes>
                  <Route path="/signup" element={<NewUser context={this.contextObject}/> } />
                  <Route path="/:user/:page" element={<Page contextObject={this.contextObject}><Canvas context={this.contextObject} /></Page>} /> 
                  <Route path="/:user" element={<Page contextObject={this.contextObject}><Canvas context={this.contextObject} /></Page>} />
                  <Route path="/:user/:page/edit" element={<Page contextObject={this.contextObject}><Editor context={this.contextObject} /></Page>} />
                  <Route path="/:user/new" element={<Page contextObject={this.contextObject}><Editor context={this.contextObject} new={true} /></Page>} />
                  <Route path="/:user/settings" element={<Page contextObject={this.contextObject}><ChangeOptions context={this.contextObject} /></Page>} />
                  <Route path="/:user/article/:page" element={<Page contextObject={this.contextObject}><Canvas context={this.contextObject} /></Page>} />
                  <Route path="/:user/login" element={<Page contextObject={this.contextObject}>
                                                        <Login context={this.contextObject} onLogin={this.logonComplete} onCancel={this.logonComplete}/>
                                                      </Page>} />
                  <Route path="/:user/logout" element={<Page contextObject={this.contextObject}>
                                                        <LogOut/>
                                                      </Page>} />
                  <Route path="/:user/search" element={<Page contextObject={this.contextObject}><Search context={this.contextObject}/></Page>} />
                  <Route path="*" element={<Error404 /> } />
                </Routes>
              </BrowserRouter>
            </div> 
          ) : ( this.state.default ? (
              <>
                <Start context={this.contextObject} onClickLink={(user,page)=>{
                    window.location.replace("/" + user + (page != null ? "/" + page : ""));
                }}/>
                { window.location.href.endsWith("/signup") ? <NewUser context={this.contextObject} onComplete={(e)=>{ window.location = "./" + e;}}/> : <> </> }
                { window.location.href.endsWith("/login") ? <Login context={this.contextObject} onLogin={(e)=>this.logonComplete(e)} onCancel={this.logonComplete}/> : <> </> }
              </>
            ) : ( 
              <></>
            )
        )}
      </>
    );
  }
}
export default App;