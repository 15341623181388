import { ServerSettings } from './ServerSettings';
import { UserSettings } from './UserSettings';
import ServerPost, { ServerResponseType } from './ServerPost';
/** @typedef {import('./UserSettings.js').UserSettingsDef} UserSettingsDef */
/** @typedef {import('./UserSettings.js').StyleItem} StyleItem */
/**
 * @class
 */
export class Context {
    serverSettings = null;
    userSettings = null;
    userPath = null;
    user = null;
    page=null;
    sessionToken=null;
    pageTitle = null;
    /**
     * Loads the server settings directly off the server
     */
    loadServerSettings = async() => {
        if(this.serverSettings === null) {
            var s = new ServerSettings();
            this.serverSettings = await s.load();
        }
    }
    /**
     * Loads the settings from the server via a server
     * post/request.
     * @param {string} user 
     */
    loadUserSettings = async(user, force) => {
        if(this.userSettings === null || this.user !== user || force) {
            var s = new UserSettings(user, this.serverSettings.serverurl);
            var result = await s.load();
            if(result !== "failed") {
                this.userSettings = result;
                this.userPath = window.origin + "/" + user;
                this.user = user;
            } else {
                this.userSettings = null;
                this.userPath = null;
                this.user = null;
            }
        }
    }
    /**
     * There was some dort of change to the settings on the
     * server so we need to update the settings in the context
     * @returns {Boolean} true if successful
     */
    update = async() => {
        if(this.userSettings !== undefined && this.userSettings !== null) { 
            await this.loadUserSettings(this.user, true); 
            return true; 
        } else { 
            return "false"; 
        }
    }
    /**
     * Attach to this event to force an app wide refresh
     * need to place a blank setState({}); inside this
     * to set:
     *      context.appStateForceRefresh = function() {
     *          this.setState({});
     *      }
     */
    appStateForceRefresh() { }
    /**
     * Save the styles to the users settings.json
     * and then force a reload
     * @param {StyleItem[]} applyStyles 
     */
    setStyles = async(applyStyles) => { 
        /**@type {UserSettingsDef} */
        var u = this.userSettings;
        /**@type {StyleItem[]} */
        var s = u.styles;
        for(var i=0;i<s.length;i++) {
            for(var j=0;j<applyStyles.length;j++) {
                if(applyStyles[j].name === s[i].name) {
                    s[i].value = applyStyles[j].value;
                }
            }
        }
        // now save
        await this.save();
        // now force a refresh
        this.appStateForceRefresh();
    }
    save = async() => {
        // now save...
        /**@type {ServerPost} */
        var sp = new ServerPost(this);
        /**@type {ServerResponseType} */
        var response = await sp.sendCommand("savesettings", this.userSettings, true);
        if(response === ServerResponseType.success) { } // don't do anything
    }
    /**
     * Initializes this context and then tries to
     * load both the server and user setting
     * @param {callback} onLoadComplete  
     */
    init = async(onLoadComplete) => {
        /**@type {string} */
        var uri = window.location.href.replace(window.location.host, "").replace("https://","").replace("http://","");
        /**@type {Number} */
        var endPos = uri.indexOf("/",1);
        var user = null;
        if(endPos <= 0) {
            user = uri.substring(1, uri.length);
        } else {
            user = uri.substring(1, endPos);
        }
        // make sure it is not a reserved word
        var reservedWords=["settings","newuser","default","home","about","404","signin","signout","signup","blog","users","page",
                           "nofrills","new","article","search","login","logout","template"];
        if(reservedWords.includes(user)) user = null; // reset to null, because we are on a special page
        // must ALWAYS, ALWAYS, ALWAYS load server settings first... duh!
        await this.loadServerSettings();
        if(user !== undefined && user !== null && user !== "") { await this.loadUserSettings(user); }
        // all done here
        if(onLoadComplete !== null) onLoadComplete(this.user);
    }
    isLoggedIn = async() => {
        return false;
    }
    /**
     * Helper function to get the users settings
     * @param {ContextUserSettingType} type
     * @param {String} name 
     */
     getUserSetting = (type, name) => {
        var objSearch = null;
        switch(type) {
            case ContextUserSettingType.fields:
                objSearch = this.userSettings.fields;
                break;
            case ContextUserSettingType.styles:
                objSearch = this.userSettings.styles;
                break;
            case ContextUserSettingType.topmenu:
                objSearch = this.userSettings.topmenu;
                break;
            default:
                break;
        }
        for(var i=0;i<objSearch.length;i++) {
            var item = objSearch[i];
            if(item.name === name) {
                return item.value;
            }
        }
    }
    /**
     * Returns a list of items in the setting category
     * @param {ContextUserSettingType} type 
     */
    getUserSettingsList = (type) => {
        var objSearch = null;
        switch(type) {
            case ContextUserSettingType.fields:
                objSearch = this.userSettings.fields;
                break;
            case ContextUserSettingType.styles:
                objSearch = this.userSettings.styles;
                break;
            case ContextUserSettingType.topmenu:
                objSearch = this.userSettings.topmenu;
                break;
            default:
                break;
        }
        var returnList = [];
        for(var i=0;i<objSearch.length;i++) {
            returnList.push(objSearch[i]);
        }
        return returnList;
    }    
}
/**
 * @typedef {Object} ContextUserSettingType
 * @property {String} fields
 * @property {String} topmenu
 * @property {String} styles
 */
export const ContextUserSettingType = {
    fields: "fields",
    topmenu: "topmenu",
    styles: "styles",
}