import ServerPost, { ServerResponseType } from "./ServerPost";
/** @typedef {import('./ServerPost.js').BlogListItem} BlogListItem */
/** @typedef {import('./ServerPost.js').ServerResponse} ServerResponse */
/** @typedef {import('./ServerPost').ServerResponseType} ServerResponseType */
/** @typedef {import('./AppContext.js').Context} Context */
export default class Macros {
    /**@type {Context} */
    context = {};
    constructor(context) {
        this.context = context;    
    }
    processMacros = async(text) => {
        var pattern = /##MACRO:{.*}##/g;
        var macros = text.match(pattern);
        if(macros === undefined || macros === null || 
           macros.length === undefined || macros.length === null ||
           macros.length === 0) return text; // just pop out
        // we have found macros
        for(var idx = 0; idx < macros.length; idx++) {
            var macro = macros[idx];
            /**@type {String} */
            var name = macro.replace(/}##/,"").replace(/##MACRO:{/, "");
            /**@type {String} */
            var value = name.indexOf("(") > 0 ? name.split("(")[1].replace(/\(/,"").replace(/\)/,"") : null;
            name = name.indexOf("(") > 0 ? name.split("(")[0] : name;
            switch(name.toLowerCase()) {
                case "list":
                    text = await this.#listMacro(macro, text, value);
                    break;
                case "listall":
                    text = await this.#listMacro(macro, text, null, true);
                    break;
                case "recent": // the most recent post in full
                    text = await this.#listMacro(macro, text, 1, false);
                    break;
                case "prev": // the previous post
                    break;
                case "next": //the next post
                    break;
                default:
                    break;
            }
        }
        return text;
    }
    #listMacro = async(macro, originalText, value, all) => {
        var data = {
            all: all === undefined ? all = false : all,
            user: this.context.user,
            details:true,
            search: value === null ? null : value
        }
        /**@type {ServerPost} */
        var sp = new ServerPost(this.context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("list", data, false);
        if(response.type === ServerResponseType.success) {
            /**@type {BlogListItem[]} */
            var listItems = response.message;
            var listData = "";
            for(/**@type {BlogListItem}*/ let item of listItems) {
                var title = Buffer.from(item.postName, 'base64').toString('utf-8');
                listData += "- [**" + title.trim() + "**](/" + this.context.user + "/" + item.fileName + "), " +
                        "*posted*: " + new Date(item.createDate).toLocaleDateString() + ", " +
                        "*modified*: " + new Date(item.modifiedDate).toLocaleDateString() + "\r\n\r\n";
            }
            // now we take the list data and put it where the MACRO is
            // in the document using the replacement command
            /**@type {String} */
            var returnData = this.#replaceText(macro, originalText, listData);
            return returnData;
        }
    }
    #replaceText = (macro, originalText, replacementText) => {
        originalText = originalText.replace(macro, replacementText);
        return originalText;
    }
}