import React from "react";
export class Error404 extends React.Component {
    render() {
        var user = this.props.user;
        var page = this.props.page;
        return (
            <div>
                <h3>ERROR 404</h3>
                <hr/>
                <h4>
                    {(user != null && page == null) ? <span>The user {user} was not found.</span> : null} 
                    {(user == null && page != null) ? <span>The page {page} cannot be found.</span> : null} 
                    {(user != null && page != null)? <span>The user ({user}) and/or user page ({page}) was not found on the server.</span> : null}
                    {(user == null && page == null)? <span>The page was not found.</span> : null}
                </h4>
                <a href="https://blog.kryl.com">GO HOME</a>
            </div>
        );
    }
}
export default Error404;