import React from "react";
import { useNavigate } from "react-router-dom";
import ServerPost, {ServerResponseType} from './ServerPost';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
/** @typedef {import('./ServerPost').ServerResponse} ServerResponse */
/** @typedef {import('./ServerPost.js').BlogItem} BlogItem */
export default function NavButtons(props) {
    const nav = useNavigate();
    const leftStyle = {
        position: "relative",
        float:"left",
        left: 0,
    }
    const rightStyle = {
        position: "relative",
        float: "right",
        right:0,
    }
    const buttonStyle = {
        cursor: "pointer",
        paddingTop: "8px",
        paddingLeft: "12px",
        color: "var(--textColor)",
        backgroundColor:"transparent",
        fontSize: "16pt",
        border: "none",
        width:"32px",
        height:"32px"
    }
    const buttonHover = {
        cursor: "pointer",
        paddingTop: "8px",
        paddingLeft: "12px",
        color: "var(--bgColor)",
        backgroundColor:"var(--textColor)",   
        fontSize: "16pt",
        border: "none", 
        width:"32px",
        height:"32px"
    }
    const centerStyle = {
        display:"inline-block",
        margin: "0, auto",

    }
    const [leftButtonStyle, setLeftButtonStyle] = React.useState( {leftButtonStyle: buttonStyle});
    const [rightButtonStyle, setRightButtonStyle] = React.useState({rightButtonStyle: buttonStyle});
    const [leftButtonText, setLeftButtonText] = React.useState( {leftButtonText: <span>&lt;</span>});
    const [rightButtonText, setRightButtonText] = React.useState( {rightButtonText: <span>&gt;</span>});
    const [constructorHasRun, setConstructorHasRun] = React.useState(false);
    const constructor = () => {
        if (constructorHasRun) return;
        setLeftButtonStyle(buttonStyle);
        setRightButtonStyle(buttonStyle);
        setLeftButtonText(<span>&lt;</span>);
        setRightButtonText(<span>&gt;</span>);
        setConstructorHasRun(true);
    };
    constructor(); // called on each re-render
    const sendCommand = async(command) => {
        var data = { 
                        user: props.context.user,
                        currentpage: props.context.page,
                        direction: command
                    };
        
        /**@type {ServerPost} */
        var sp = new ServerPost(props.context);
        var response = await sp.sendCommand("walk", data, false);
        if(response.type === ServerResponseType.success) {
            // we have the name of the next file
            var url = "/" + props.context.user + "/" + response.message;
            nav(url); // navigate to that page
        }
    }
    return( 
        props.context.page === "home" || props.context.page === "about" || 
        props.context.page === null || props.context.page === "" ? <></> :
        <h1>
            <div style={leftStyle}>
                <div title="previous post" style={leftButtonStyle} 
                        onMouseEnter={()=>{
                            setLeftButtonStyle(buttonHover);
                            setLeftButtonText(<span>&lt;</span>);
                        }}
                        onMouseLeave={()=>{
                            setLeftButtonStyle(buttonStyle);
                            setLeftButtonText(<span>&lt;</span>);
                        }}
                        onClick={()=>sendCommand("prev")}>{leftButtonText}</div>
            </div>
            <div style={centerStyle}>{props.title}</div>
            <div style={rightStyle}>
                <div title="next post" style={rightButtonStyle}
                        onMouseEnter={()=>{
                            setRightButtonStyle(buttonHover);
                            setRightButtonText(<span>&gt;</span>);
                        }}
                        onMouseLeave={()=>{
                            setRightButtonStyle(buttonStyle);
                            setRightButtonText(<span>&gt;</span>);
                        }}
                        onClick={()=>sendCommand("next")}>{rightButtonText}</div>            
            </div>
        </h1>
    )
}