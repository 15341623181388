import React from 'react';
import {ContextUserSettingType} from './AppContext';
import { CommonDialogWrapper } from './CommonDialogWrapper';
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
export class ChangeStyles extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    constructor(props) {
        super(props);
        this.state = {
            applyButtonDisabled: true,
            textColor: this.props.context.getUserSetting(ContextUserSettingType.styles, "textColor"),
            bgColor: this.props.context.getUserSetting(ContextUserSettingType.styles,"bgColor"),
            headerTextColor: this.props.context.getUserSetting(ContextUserSettingType.styles,"headerTextColor"),
            headerBgColor: this.props.context.getUserSetting(ContextUserSettingType.styles, "headerBgColor"),
            fonts:this.props.context.getUserSetting(ContextUserSettingType.styles,"fonts")
        }
    }
    apply = () => {
        this.dialogWrapper.showYesNoCancel("Apply Styles", 
            "Are you sure you want to apply the selected styles?",
            ()=> {
                var applyStyles = [
                    {
                        "name": "textColor",
                        "value": this.state.textColor
                    },
                    {
                        "name": "bgColor",
                        "value": this.state.bgColor
                    },
                    {
                        "name": "headerBgColor",
                        "value": this.state.headerBgColor
                    },
                    {
                        "name": "headerTextColor",
                        "value": this.state.headerTextColor
                    },
                    {
                        "name": "fonts",
                        "value": this.state.fonts
                    }
                ];
                // save the styles to the server
                this.props.context.setStyles(applyStyles);
                this.setState({applyButtonDisabled:true});
            });
    }
    render() {
        return (
            <div>
                <h3>Styles</h3>
                <span>
                    These are the styles (colors and fonts) you can use for your blog.
                </span><br/><br/>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td><span>Text Color:</span>{'  '}</td>
                            <td><input type="color" value={this.state.textColor} onChange={(e)=>{this.setState({applyButtonDisabled:false, textColor:e.target.value})}} /></td>
                        </tr>
                        <tr>
                            <td><span>Background Color:</span>{'  '}</td>
                            <td><input type="color" value={this.state.bgColor} onChange={(e)=>{this.setState({applyButtonDisabled:false, bgColor:e.target.value})}} /></td>
                        </tr>
                        <tr>
                            <td><span>Header Text Color:</span>{'  '}</td>
                            <td><input type="color" value={this.state.headerTextColor} onChange={(e)=>{this.setState({applyButtonDisabled:false, headerTextColor:e.target.value})}} /></td>
                        </tr>
                        <tr>
                            <td><span>Header Background Color:</span>{'  '}</td>
                            <td><input type="color" value={this.state.headerBgColor} onChange={(e)=>{this.setState({applyButtonDisabled:false, headerBgColor:e.target.value})}} /></td>
                        </tr>
                        <tr>
                            <td><span>Font:</span>{'  '}</td>
                            <td>
                                <select value={this.state.fonts} onChange={(e)=>{this.setState({applyButtonDisabled:false, fonts:e.target.value})}}>
                                    <option className="segoeOption">'Segoe UI', Tahoma, Geneva, Verdana, sans-serif</option>
                                    <option className="sourceCodeOption">source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace</option>
                                    <option className="timesOption">"Times New Roman", Times, serif</option>
                                    <option className="arialOption">Arial, Helvetica, sans-serif</option>
                                    <option className="lucidiaOption">"Lucidia Console", "Courier New", monospace</option>
                                    <option className="tahomaOption">Tahoma, Verdana, sans-serif</option>
                                    <option className="fantasyOption">Fantasy, Copperplate, sans-serif</option>
                                    <option className="fantasy2Option">Papyrus, sans-serif</option>
                                </select> 
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><button disabled={this.state.applyButtonDisabled} onClick={this.apply}>Apply Change...</button></td>
                        </tr>
                    </tbody>
                </table>
                {this.dialogWrapper.renderDialogElement()}
            </div>
        );
    }
}