import React from "react";
export default class EditorRibbon extends React.Component {
    hoverColor = adjust(this.props.color, 60);
    defaultColor = this.props.color;
    activeColor = adjust(this.props.color, 80);
    selectedTab = this.props.defaultTab;
    styleButton = {
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px " + this.defaultColor + " solid",
        outline: "none",
        padding: "5px 5px",
        backgroundColor: this.defaultColor,
        cursor: "pointer",
        margin: "3px",
        color: "inherit",
    };
    styleActive = {
        borderTop: "2px inherit solid",
        borderBottom: "2px " + this.activeColor + " solid",
        borderRight: "none",
        borderLeft: "none",
        outline: "none",
        padding: "5px 5px",
        cursor: "default",
        margin: "3px",
        backgroundColor: this.activeColor,
        color: "inherit",
    };
    styleChildPanel = {
        backgroundColor: this.activeColor,
        borderBottom: "1px " + this.activeColor + " solid",
        borderTop: "1px " + this.activeColor + " solid",
        padding: "3px",
        marginTop:"-2px",
    }
    styleTabPanel = {
        borderBottom: "1px " + this.activeColor + " solid",
        backgroundColor: this.defaultColor,
        padding: "0px",
        marginBottom:"-2px",
    }
    styleRibbonContainer = {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    }
    onMouseEnter = (e) => {
        var idx = Number.parseInt(e.target.id);
        if(idx !== this.selectedTab) {
            e.target.style.backgroundColor = this.hoverColor
        }
    }
    onMouseLeave = (e) => {
        console.log(e);
        var idx = Number.parseInt(e.target.id);
        if(idx !== this.selectedTab) {
            e.target.style.backgroundColor = this.defaultColor;
        }
    }
    onClick = (e) => {
        var idx = Number.parseInt(e.target.id);
        var label = e.target.innerText;
        this.selectedTab = idx;
        this.props.onTabSelection(label, idx);
    }
    render() {
        return (
            <div id="RibbonContainer" style={this.styleRibbonContainer}>
                <div id="RibbonTabPanel" style={this.styleTabPanel}>
                {this.props.children.map((child, idx) => {
                    return (
                        <div id={"RibbonTab" + idx} key={idx} style={{float:'left'}}>
                            <button key={"button"+idx} type='button' style={idx === this.selectedTab ? this.styleActive : this.styleButton} 
                                    onMouseEnter={(e)=>{
                                        if(idx !== this.selectedTab) {
                                            e.target.style.backgroundColor = this.hoverColor
                                        }
                                    }}
                                    onMouseLeave={(e)=>{
                                        if(idx !== this.selectedTab) {
                                            e.target.style.backgroundColor = this.defaultColor;
                                        }
                                    }}
                                    onClick={()=>{
                                        this.selectedTab = idx;
                                        this.props.onTabSelection(child.props.label, idx);
                                    }}>
                                {child.props.label}
                            </button>
                        </div>
                    )
                })}
                </div>
                <div key={"children"} style={this.styleChildPanel}>
                    {this.props.children.map((child, idx) => { return this.selectedTab === idx ? child.props.children: null; })}
                </div> 
            </div>
        )
    }
}       

export class EditorRibbonTab extends React.Component {
    render() { return  null; }
}

function adjust(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}