import React from "react";
import CommonDialogWrapper from "./CommonDialogWrapper";
import ServerPost, {ServerResponseType} from "./ServerPost";
import Captcha from "./Captcha";
import { Emoji } from "./Emoji";
import md5 from "md5";
/** @typedef {import('./ServerPost.js').ServerResponse} ServerResponse */
export default class NewUSer extends React.Component {
    /** @type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    dimPageStyle = {
        position: "absolute",
        width: "100vw",
        height: "100vh",
        left:0,
        top:0,
        backgroundColor: "rgba(0,0,0,0.5)",
        xIndex: "10000"
    }
    floatingDialogStyle = {
        position: "relative",
        top:0,
        left:0,
        right:0,
        bottom:0,
        margin: "auto",
        display:"table",
        borderWidth: "2px",
        borderColor: "var(--textColor)",
        borderStyle: "solid",
        backgroundColor: "var(--bgColor)",
        padding: "10px",
        zIndex: 90000
    }
    rightAlignstyle = {
        float: "right"
    }
    fullLengthStyle = {
        width: "100%",
    }
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            startTime: Date.now(),
            step: 0,
            name: "",
            title: "",
            author: "",
            emoji: "",
            emojiDialog: false,
            suggestions: "",
            email: "",
            needsEmailCode: false,
            codeCheck: "",
            code: "",
            password: "",
            reenter: "",
            showCaptcha: false,
            guid: "",
            guidChallenge: "",
        }
    }
    /**
     * 
     * @param {KeyboardEvent} e 
     */
    validateInput = (e) => {
        if(e.altKey || e.ctrlKey) e.preventDefault();
        if(!e.key.match("^[a-z0-9]*$")) e.preventDefault();
    }
    nextClick = async() =>{
        switch(this.state.step) {
            case 0: 
                if(await this.validateBasicInfo() === true) {
                    this.setState({step:1});
                }
                break;
            case 1:
                if(await this.validateEmail() === true) {
                    this.setState({step:2});
                }
                break;
            case 2:
                if(await this.validatePassword() === true) {
                    this.setState({step:3});
                }
                break;
            case 3:
                // does not exist - next button disabled
                // the user has to confirm the captcha 
                // at this phase...
                break;
            case 4:
                if(await this.finishSetup() === true) {
                    this.setState({hidden:true},
                        this.dialogWrapper.showAlert("Welcome", "You blogging experience has begin. Have fun!", 
                            ()=>{if(this.props.onComplete) this.props.onComplete(this.state.name);})); // send the name of the new user
                }
                break;
            default:
                break;
        }
    }
    /**
     * Validates that the name is not taken, suggest names if it is
     * and then validates that the user set a title and specified 
     * an emoji.
     * @returns {Boolean}
     */
    validateBasicInfo = async() => {
        if(this.state.emoji === "") {
            this.setState({hidden:true}, 
                this.dialogWrapper.showAlert("Required Input", "You must specify an emoji to continue.", 
                    ()=>this.setState({hidden:false})));
            return false;
        }
        if(this.state.title === "") {
            this.setState({hidden:true}, 
                this.dialogWrapper.showAlert("Required Input", "You must specify a title to continue.",
                    ()=>this.setState({hidden:false})));
            return false;
        }
        var reservedWords=["settings","newuser","default","home","about","404","signin","signout","signup","blog","users","page",
                           "nofrills","new","article","search","login","logout", "template"];
        if(this.state.name === "" || reservedWords.includes(this.state.name.toLowerCase()) || this.state.name.length < 3) {
            this.setState({hidden:true}, 
                this.dialogWrapper.showAlert("Required Input", <span>You must specify a valid name to continue.<br/>
                                                               <ul>
                                                                   <li>Not a reserved <span style={{textDecoration:"underline"}} title={reservedWords.toLocaleString()}>word (?).</span></li>
                                                                   <li>More than 3 characters and less than 12 characters in length.</li>
                                                                   <li>Only lowercase letters and numbers, no spaces or special characters.</li>
                                                               </ul>
                                                               </span>,
                    ()=>this.setState({hidden:false})));
            return false;
        }
        // ok, this part is more complicated - ask the server if the name if ok,
        // if not, the error will have a list of suggestions from the server
        var serverContext = {serverurl: this.props.context.serverSettings.serverurl, user: null};
        var data = { newblogname: this.state.name.toLowerCase() };
        /**@type {ServerPost} */
        var sp = new ServerPost(serverContext);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("new-checkname", data, false);
        if(response.type !== ServerResponseType.success) {
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("Name Taken", "The name you specified is already taken. \r\n" +
                                                       "Here are some suggestions:\r\n" + response.message,
                    ()=>this.setState({hidden:false})));
            return false;
        } else {
            // if we made it this far -- all is good
            return true;
        }
    }
    /**
     * Confirms the email is valid and then contacts the server to send a message to the user
     * we will then get back a response with the answer which we send back to the server before
     * we move to the next step in setup...
     * @returns {null}
     */
    confirmEmail = async() => {
        // first verify we have an email address
        const regex  =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var match = this.state.email.match(regex);
        if(match === null || match.length <= 0) {
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("Invalid Email", "The email address you entered is invalid.",
                    ()=>this.setState({hidden:false})));
            return;
        }
        // next ask the server to send a code -- we get back a confirmation code we sent to verify
        // because the server is stateless we will have the answer embedded in the result and double
        // encrypted so it cannot be hacked out of the result...
        var serverContext = {serverurl: this.props.context.serverSettings.serverurl, user: null};
        var data = { newemail: this.state.email };
        /**@type {ServerPost} */
        var sp = new ServerPost(serverContext);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("new-email", data, false);
        if(response.type !== ServerResponseType.success) {
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("Error", "The server responded with: " + response.message,
                    ()=>this.setState({hidden:false})));
            return;
        }
        
        // if we are here, we are good
        this.setState({needsEmailCode:true, codeCheck: response.message});      
    }
    /**
     * Validates that the user has a good email address 
     * @returns {Boolean}
     */
    validateEmail = async() => {
        if(this.state.needsEmailCode === false) {
            this.setState({hidden:true}, 
                this.dialogWrapper.showAlert("Validation Required", "Please validate your email address to proceed.",
                    ()=>this.setState({hidden:false})));
            return false;
        }
        if(this.state.code === "") {
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("Validation Required", "You must supply the code sent to your email address to proceed.",
                    ()=>this.setState({hidden:false})));
            return false;
        }
        // ok we are here, next we validate the the response form the server and the
        // code the suer entered are valid...
        var serverContext = {serverurl: this.props.context.serverSettings.serverurl, user: null};
        var data = { code: this.state.code, codecheck: this.state.codeCheck };
        /**@type {ServerPost} */
        var sp = new ServerPost(serverContext);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("new-confirmemail", data, false);
        if(response.type !== ServerResponseType.success) {
            this.setState({hidden:true}, 
                this.dialogWrapper.showAlert("Error", "There was an unknown server error.",
                    ()=>this.setState({hidden:false})));
            return false;
        } else {
            return true;
        }
    }
    /**
     * Validates that the user entered passwords that match and it meets the requirements
     * @returns {Boolean}
     */
    validatePassword = async() => {
        if(this.state.password !== this.state.reenter) {
            this.setState({hidden:true}, 
                this.dialogWrapper.showAlert("Error", "Your passwords do not match. Please try again.",
                    ()=>this.setState({hidden:false})));
            return false;
        }
        if(this.complexityScore(this.state.password) < 4) {
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("Error", "Your password does not meet the complexity requirements.",
                    ()=>this.setState({hidden:false})));
            return false;
        }
        // if we are here, we are good
        return true;
    }
    /**
     * Checks how complex a password is and returns a score from 0 to 6
     * @param {String} password 
     * @returns {Number}
     */
    complexityScore = (password) => {
        //Regular Expressions.
        var regex = [];
        regex.push(/[A-Z]/g); //Uppercase Alphabet.
        regex.push(/[a-z]/g); //Lowercase Alphabet.
        regex.push(/[0-9]/g); //Digit.
        //eslint-disable-next-line
        regex.push(/[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g); //Special Characters.
        var passed = 0;
        if(password.length >= 8) passed++;
        else return 0;
        //Validate for each Regular Expression.
        for (var i = 0; i < regex.length; i++) {
            if (new RegExp(regex[i]).test(password)) {
                passed++;
            }
        }
        return passed;
    }
    /**
     * Completes setting up the account by sending all the 
     * data to the server to create a new user account
     */
    finishSetup = async()=> {
        // first verify the user did not take more than 15 minutes to setup their account
        var rightNow = Date.now();
        var timeDiff = (rightNow - this.state.startTime)/1000; //in s
        // first of all if too fast - it is a bot
        if(timeDiff < 30) {
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("WARNING", "You appear to be a bot. You filled this in way too fast.",
                    ()=>this.setState({hidden:false})));
            this.setState({startTime:Date.now()}, window.alert("BOT WARNING"));
            this.setState({step:0});
            return false;
        } else if(timeDiff > 900) { // 15 minutes
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("Too Slow", "Sorry, you took longer than 15 minutes to fill out this information. Please try again.", 
                    ()=>this.setState({hidden:false})));
            this.setState({step:0});
            return false;
        }
        var serverContext = {serverurl: this.props.context.serverSettings.serverurl, user: null};
        var data = {
            user: this.state.name,
            author: this.state.author,
            email: this.state.email.toLowerCase(), // MUST - because we md5 hash
            title: this.state.title,
            emoji: this.state.emoji,
            username: md5(this.state.email),
            password: md5(this.state.password),
            guid: this.state.guid,
            guidChallenge: this.state.guidChallenge,
        }
        /**@type {ServerPost} */
        var sp = new ServerPost(serverContext);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("new-user", data, false);
        if(response.type !== ServerResponseType.success) {
            this.setState({hidden:true},
                this.dialogWrapper.showAlert("Error", "There was an unknown server error.",
                this.setState({hidden:false})));
            return false;
        }
        // all here - good
        return true;
    }
    /**
     * We return the result from the captcha and export it for use with publish
     * @param {Object[]} guidData 
     */
    confirmCaptcha = (guidData) => {
        console.log(guidData["guid"] + " -- " + guidData["guidChallenge"]);
        this.setState(
            {
                guid: guidData["guid"],
                guidChallenge: guidData["guidChallenge"],
                showCaptcha:false, 
                step:4
            });
    }
    /**
     * Return the rendered component
     * @returns {HTMLElement}
     */
    render() {
        return (
            <>
            {this.state.hidden === false ?
                <div id="dimPage" style={this.dimPageStyle}>
                    <div id="dialog" style={this.floatingDialogStyle}>
                    {this.state.step === 0 ? 
                        <>
                            <h2>Welcome</h2>
                            <p>Your adventure in blogging is about to begin. <br/>
                            To start lets collect some information about your blog:</p>
                            <ol>
                                <li>
                                    What name do you want in the URL: 
                                    <input style={this.rightAlignstyle} type="text" value={this.state.name} 
                                           maxLength={12}
                                           onKeyPress={this.validateInput}
                                           onChange={(e)=>{this.setState({name:e.target.value})}} />
                                </li>
                                <li>
                                    What title do you want to give your blog: 
                                    <input maxLength={30} style={this.rightAlignstyle} type="text" value={this.state.title} onChange={(e)=>{this.setState({title:e.target.value})}} />
                                </li>
                                <li>
                                    What is your name: 
                                    <input maxLength={40} style={this.rightAlignstyle} type="text" value={this.state.author} onChange={(e)=>{this.setState({author:e.target.value})}} />
                                </li>
                                <li>
                                    What emoji would you like for your site: 
                                    <input style={this.rightAlignstyle} disabled={true} type="text" value={this.state.emoji} onChange={(e)=>{this.setState({emoji:e.target.value})}} />
                                    <button onClick={()=>{this.setState({emojiDialog: !this.state.emojiDialog})}}>  <span role="img" aria-label='Insert an emoji'>😀</span></button>
                                </li>
                            </ol>                    
                        </>
                    : <></>}
                    {this.state.step === 1 ?
                        <>
                            <h2>Your Info</h2>
                            <p>Now we need your email address so we can do two things:</p>
                            <ul>
                                <li>Verify you are real.</li>
                                <li>Setup two factor authentication.</li>
                            </ul>
                            <input maxLength={60} type="text" disabled={this.state.needsEmailCode} style={this.fullLengthStyle} value={this.state.email} 
                                               onChange={(e)=>this.setState({"email": e.target.value})} /><br/>
                            <p>Once you have entered your email address click "Validate..."</p>
                            <button disabled={this.state.needsEmailCode} onClick={this.confirmEmail}>Validate...</button>
                            {this.state.needsEmailCode ? 
                                <>
                                    <p>The code sent to your email address: <input type="text" style={this.rightAlignstyle} onChange={(e)=>this.setState({"code": e.target.value})} /> </p>
                                    <p>Please check your JUNK email folder as it might go there.</p>
                                </>
                            : <></>}
                        </>
                    : <></>
                    }
                    {this.state.step === 2 ?
                        <>
                            <h2>Password</h2>
                            <p>Next, please supply a password. It must meet the following guidelines:</p>
                            <ul>
                                <li>It must be 8 characters or longer.</li>
                                <li>It must have at least one capital letter.</li>
                                <li>It must have at least one number.</li>
                                <li>It must have at least one special character.</li>
                            </ul>
                            <p>Password: <input type="password" style={this.rightAlignstyle} onChange={(e)=>this.setState({"password": e.target.value})} /></p>
                            <p>Reenter: <input type="password" style={this.rightAlignstyle} onChange={(e)=>this.setState({"reenter": e.target.value})} /></p>
                        </>
                    : <></> }
                    {this.state.step === 3 ?
                        <>
                            {this.state.showCaptcha === false ?
                                <>
                                <h2>Final Verification</h2>
                                <p>Finally, we need to validate you are human. Verify the captcha and you are good to go.</p>
                                <p>Click "Verify..."" to confirm.</p>
                                <button onClick={()=>this.setState({showCaptcha:true})}>Verify...</button>
                                </>
                            : 
                                <Captcha nouser={true} context={{serverurl:this.props.context.serverSettings.serverurl,user:null}} 
                                         onConfirm={this.confirmCaptcha} /> 
                            }
                        </>
                    : <></> }
                    {this.state.step === 4 ?
                        <>
                            <h2>Ready to Go!</h2>
                            <p>All done collecting information. Click "Next" to build your site.<br/>
                               Once complete, you will be take to your site and you can log in from the menu.</p>
                        </>
                    : <></> }
                    {/** These items below are GLOBAL to the dialog */}
                    <div style={this.rightAlignstyle}>
                        <button onClick={()=>window.location.href="/"}>Cancel</button>
                        <button disabled={this.state.step === 3} onClick={this.nextClick}>Next &gt;</button>
                    </div>
                    {this.state.emojiDialog ? 
                        <Emoji emojiSelected={(e)=> {
                            this.setState({emoji:e.native, emojiDialog: false});
                        }}/> 
                    : <></> }
                    </div>
                </div>
            :<></> }
            {this.dialogWrapper.renderDialogElement()}
            </>
        )
    }
}