import React from 'react';
import Dialog from './Dialog';
/** @typedef {import('./Dialog.js').DialogField} DialogField */
/** @typedef {import('./Dialog.js').DialogButton} DialogButton */
/** @typedef {import('./Dialog.js').DialogOptions} DialogOptions */
/**
 * Everything below here is for the common dialog, but specifically so
 * that we can call dialog functions from the context object that gets
 * passed into EVERY child from here on out. That way it is easy to get
 * the dialogs to appear and we only have the code in one place to make
 * then appear where we want them to.
 */
export class CommonDialogWrapper {
    callerThis = null;
    /**
     * Constructor takes a reference to the object it is in (this)
     * @param {React.Component} thisComponent 
     */
    constructor(thisComponent) {
        this.setDialogCalls(); // configured the functions
        this.callerThis = thisComponent;
    }
    showAlert(title, message, onCloseEvent){}
    showYesNoCancel(title, message, onConfirmEvent, onCancelEvent){}
    showLogin(onConfirmEvent, onCancelEvent){}
    showInputbox(title, message, defaultValue, onConfirmEvent, onCancelEvent){}
    showChangePassword(onConfirmEvent, onCancelEvent){}
    showFFA(onConfirmEvent, onCancelEvent){}
    showKeyValue(title, message, defaultValue, onConfirmEvent, onCancelEvent, keyLabel, valueLabel){}
    showFileBrowser(title, message, types, onConfirmEvent, onCancelEvent) {}
    hide(){this.show = false;}
    /**@type {Boolean} */
    show = false;
    /**@type {String} */
    defaultValue = "";
    /**@type {CommonDialogs.DialogType} */
    dialogType = "alert";
    /**@type {String} */
    dialogTitle = "No Frills Blog";
    /**@type {String} */
    dialogMessage = "";
    /**@type {String} */
    dialogFileTypes = "";
    /**
     * Return from the dialog
     * @param {String[]} value 
     */
    onConfirm(dialogResults){}
    /**
     * On Cancel
     */
    onCancel(){}
    /**
     * On password reset request
     */
    onResetRequest(){}
    /**@type {String} */
    keyLabel = "";
    /**@type {String} */
    valueLabel = "";
    /**
     * This function sets up all the dialog calls that we will use in
     * the application so it is a simple call to show an alert, for
     * example the following:
     *  
     *    this.props.context.showAlert("hello", "world");
     */
    setDialogCalls = () => {
        var thisRef = this;
        // now we add the show dialog to the context object
        // we do this so that all calls will pop up on the app page
        // from all the child windows here, with one easy call
        this.showAlert = function(title, message, onClose) {
            thisRef.dialogType = "alert"
            thisRef.dialogTitle = title;
            thisRef.dialogMessage = message;
            thisRef.onCancel = onClose;
            thisRef.show = true;
            thisRef.callerThis.setState({});
        } 
        this.showYesNoCancel = function(title, message, onConfirm, onCancel) {
            thisRef.dialogType = "yesNoPrompt"
            thisRef.dialogTitle = title;
            thisRef.dialogMessage = message;
            thisRef.onConfirm = onConfirm;
            thisRef.onCancel = onCancel;
            thisRef.show = true;
            thisRef.callerThis.setState({});
        } 
        this.showLogin = function(onConfirm, onCancel, onReset) {
            thisRef.dialogType = "login"; 
            thisRef.onConfirm = onConfirm;
            thisRef.onCancel = onCancel;
            thisRef.onResetRequest = onReset;
            thisRef.show = true;
            thisRef.callerThis.setState({});
        }
        this.showFFA = function(onConfirm, onCancel) {
            thisRef.dialogType = "ffa"; 
            thisRef.show = true;
            thisRef.onConfirm = onConfirm;
            thisRef.onCancel = onCancel;
            thisRef.callerThis.setState({});
        }
        this.showInputbox = function(title, message, defaultValue, onConfirm, onCancel) {
            thisRef.dialogType = "inputbox"; 
            thisRef.dialogTitle = title;
            thisRef.dialogMessage = message;
            thisRef.defaultValue = defaultValue;
            thisRef.show = true;
            thisRef.onConfirm = onConfirm;
            thisRef.onCancel = onCancel;
            thisRef.callerThis.setState({});
        }
        this.showKeyValue = function(title, message, defaultValue, onConfirm, onCancel, keyLabel, valueLabel) {
            thisRef.dialogType = "keyValue"; 
            thisRef.dialogTitle = title;
            thisRef.dialogMessage = message;
            thisRef.keyLabel = keyLabel;
            thisRef.valueLabel = valueLabel;
            thisRef.defaultValue = defaultValue;
            thisRef.show = true;
            thisRef.onConfirm = onConfirm;
            thisRef.onCancel = onCancel;
            thisRef.callerThis.setState({});
        }
        this.showChangePassword = function(onConfirm, onCancel) {
            thisRef.dialogType="change";
            thisRef.show = true;
            thisRef.onConfirm = onConfirm;
            thisRef.onCancel = onCancel;
            thisRef.callerThis.setState({});
        }
        this.showFileBrowser = function(title, message, type, onConfirm, onCancel) {
            thisRef.dialogType="file";
            thisRef.show = true;
            thisRef.dialogFileTypes = type;
            thisRef.dialogTitle = title;
            thisRef.dialogMessage = message;
            thisRef.onConfirm = onConfirm;
            thisRef.onCancel = onCancel;
            thisRef.callerThis.setState({});
        }
        this.renderDialogElement = () => {          
            return (
                <React.Fragment>
                    <CommonDialogs key={new Date().getTime()}  ref={(instance) => (this.CommonDialogs = instance)} wrapper={this}/>
                </React.Fragment>
            )
        }
            
    }
}
export default CommonDialogWrapper;

/**
 * Displays a common set of dialogs used throughout the application
 * @property {DialogType} type
 * @property {String} title
 * @property {String} message
 * @property {function(string[])} onConfirm
 * @property {String} keyLabel
 * @property {String} valueLabel
 * @property {Boolean} show
 */
class CommonDialogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false
        }
    }   
    /** 
     * When anything updates from the parent we  get called here
     * @param {React.PropsWithRef} props 
     * @param {React.ComponentState} state
     */
    static getDerivedStateFromProps(props, state) {
        return {showDialog: props.wrapper.show};
    }
    /**
     * @typedef {Enum} DialogTypes
     */
    dialogType = {
        alert: "alert",
        yesNoPrompt: "yesNoPrompt",
        inputbox: "inputbox",
        login: "login",
        ffa: "ffa",
        keyValue: "keyValue",
        change: "change",
        file: "file"
    }
    /**
     * Prepare for an ALERT dialog
     */
    alert = () => {
        var dialogButtons = [];
        dialogButtons.push({ label:"OK", callback:this.closeDialog, cancel: true});
        this.dialogOptions = {}; // reset
        this.dialogOptions.dialogButtons = dialogButtons;
        this.dialogOptions.description= <div children={this.props.wrapper.dialogMessage}></div>;
        this.dialogOptions.heading = <div>{this.props.wrapper.dialogTitle}</div>;
        this.dialogOptions.modal = true;   
    }
    /**
     * Prepare for a YES/NO/CANCEL dialog
     */
    yesNoPrompt = () => {
        var dialogButtons = [];
        dialogButtons.push({ cancel:true, label:"Cancel", callback:this.closeDialog});
        dialogButtons.push({ cancel:true, label:"No", callback:this.closeDialog, buttonValue: "NO"});
        dialogButtons.push({ label:"Yes", callback:this.closeDialog, cancel: false, buttonValue:"YES"});
        this.dialogOptions = {}; // reset
        this.dialogOptions.dialogButtons = dialogButtons;
        this.dialogOptions.description= <div>{this.props.wrapper.dialogMessage}</div>;
        this.dialogOptions.heading = <div>{this.props.wrapper.dialogTitle}</div>;
        this.dialogOptions.modal = true;
    }
    /**
     * Prepare for a TWO FACTOR dialog
     */
    ffa = () => {
        /**@type {DialogField[]} */
        var twofactorFields = [];
        twofactorFields.push({type:"text", label:"Your code: ", value:"", default: true, defaultValue: "", required: true});
        var twofactorButtons = [];
        twofactorButtons.push({label:"Cancel", callback:this.closeDialog, default:false, cancel:true });
        twofactorButtons.push({label:"Confirm", callback:this.closeDialog, default:true, cancel:false });
        this.dialogOptions = {};
        this.dialogOptions.dialogButtons = twofactorButtons;
        this.dialogOptions.dialogFields = twofactorFields;
        this.dialogOptions.description = <><p>A 2-factor code has been sent to your email. Please look for an email from:</p>
                                            <p>&nbsp;&nbsp;&nbsp;<i>Two-Factor Admin (DO NOT REPLY) admin@zphire.net</i></p>
                                            <p>Please check your JUNK mail in case you do not receive it in your inbox.</p> 
                                            <hr/>
                                            <p>Once you have the code, please enter it here and click Confirm:</p></>;
        this.dialogOptions.heading = "Two-Factor Authentication";
        this.dialogOptions.modal = true;
    }
    /**
     * Prepare for an INPUTBOX dialog
     */
    inputBox = () => {
        /**@type {DialogField[]} */
        var dialogFields = [];
        var val = this.props.wrapper.defaultValue === undefined ? "" : this.props.wrapper.defaultValue;
        dialogFields.push({type:"text", label:"Answer: ", value:"", default: false, defaultValue: val, required: true});
        /**@type {DialogButton[]} */
        var dialogButtons = [];
        dialogButtons.push({label:"OK", callback:this.closeDialog, default:true, cancel:false });
        dialogButtons.push({label:"Cancel", callback:this.closeDialog, default:false, cancel:true });
        this.dialogOptions = {};
        this.dialogOptions.dialogButtons = dialogButtons;
        this.dialogOptions.dialogFields = dialogFields;
        this.dialogOptions.heading = <div>{this.props.wrapper.dialogTitle}</div>;
        this.dialogOptions.description=<div>{this.props.wrapper.dialogMessage}</div>;
        this.dialogOptions.modal = true;
    }
    file = () => {
        /**@type {DialogField[]} */
        var dialogFields = [];
        dialogFields.push({type:"file", label:"File: ", default: false, required: true, accepts: this.props.wrapper.dialogFileTypes});
        /**@type {DialogButton[]} */
        var dialogButtons = [];
        dialogButtons.push({label:"OK", callback:this.closeDialog, default:true, cancel:false });
        dialogButtons.push({label:"Cancel", callback:this.closeDialog, default:false, cancel:true });
        this.dialogOptions = {};
        this.dialogOptions.dialogButtons = dialogButtons;
        this.dialogOptions.dialogFields = dialogFields;
        this.dialogOptions.heading = <div>{this.props.wrapper.dialogTitle}</div>;
        this.dialogOptions.description=<div>{this.props.wrapper.dialogMessage}</div>;
        this.dialogOptions.modal = true;    
    }
    /**
     * User requested to reset password, because they forgot it
     */
    resetPassword = () => {
        this.props.wrapper.hide();
        this.setState({}, ()=> {
            if(this.props.wrapper.onResetRequest !== null) this.props.wrapper.onResetRequest();
        });
    }
    /**
     * Prepare for a LOGIN dialog
     */
    loginDialog = () => {
        // format the logon screen
        /**@type {DialogField[]} */
        var dialogFields = []
        dialogFields.push({type:"text", label:"Username: ", value:"", default: false, defaultValue:"", required:true});
        dialogFields.push({type:"password", label:"Password: ", value:"", default: true, defaultValue:"", required:true});
        dialogFields.push({type:"link", label:"Forgot password", value:this.resetPassword})
        /**@type {DialogButton[]} */
        var dialogButtons = [];
        dialogButtons.push({label:"OK", callback:this.closeDialog, default:true, cancel:false });
        dialogButtons.push({label:"Cancel", callback:this.closeDialog, default:false, cancel:true });
        this.dialogOptions = {};
        this.dialogOptions.dialogButtons = dialogButtons;
        this.dialogOptions.dialogFields = dialogFields;
        this.dialogOptions.description = <div>Please enter your username and password and press OK.</div>;
        this.dialogOptions.heading = "Login";
        this.dialogOptions.modal = true;
    }
    /**
     * Displays a keys value dialog
     */
    keyValueDialog = () => {
        /**@type {String} */
        var keyText = this.props.wrapper.keyLabel === undefined || this.props.wrapper.keyLabel === null || 
                      this.props.wrapper.keyLabel === "" ? "Field Name" : this.props.wrapper.keyLabel;
        /**@type {String} */
        var valueText = this.props.wrapper.valueLabel === undefined || this.props.wrapper.valueLabel === null || 
                        this.props.wrapper.valueLabel === "" ? "Field Value" : this.props.wrapper.valueLabel;
        /**@type {DialogField[]} */
        var dialogFields = [];
        dialogFields.push({type:"text", label:keyText +": ", value:"", default: false});
        dialogFields.push({type:"text", label:valueText+": ", value:"", default: true});
        /**@type {DialogButton[]} */
        var dialogButtons = [];
        dialogButtons.push({label:"OK", callback:this.closeDialog, default:true, cancel:false });
        dialogButtons.push({label:"Cancel", callback:this.closeDialog, default:false, cancel:true });
        this.dialogOptions = {};
        this.dialogOptions.dialogButtons = dialogButtons;
        this.dialogOptions.dialogFields = dialogFields;
        this.dialogOptions.heading = <div>{this.props.wrapper.dialogTitle}</div>;
        this.dialogOptions.description=<div>{this.props.wrapper.dialogMessage}</div>;
        this.dialogOptions.modal = true;
    }
    changePassword = () => {
        /**@type {DialogField[]} */
        var passwordFields = [];
        passwordFields.push({type:"password", label:"New password: ", value:"", default: false, required: true});
        passwordFields.push({type:"password", label:"Confirm password: ", value:"", default: true, required: true});
        /**@type {DialogButton[]} */
        var passwordButtons = [];
        passwordButtons.push({label:"Change", callback:this.closeDialog, default:true, cancel:false });
        passwordButtons.push({label:"Cancel", callback:this.closeDialog, default:false, cancel:true });
        this.dialogOptions = {};
        this.dialogOptions.dialogButtons = passwordButtons;
        this.dialogOptions.dialogFields = passwordFields;
        this.dialogOptions.description = <><p>Please enter and confirm your new password.<br/>
                                              Rules: Must be longer than 8 characters and have 3 of the following:</p>
                                              <ol>
                                                  <li>An upper-case character</li>
                                                  <li>A lower-case character</li>
                                                  <li>A number</li>
                                                  <li>A special character</li>
                                              </ol></>;
        this.dialogOptions.heading = "New Password";
        this.dialogOptions.modal = true;
    }
    /**
     * Close the dialog and return the results to the caller
     * as an array of strings
     * @param {string[]} dialogResults 
     */
    closeDialog = async(dialogResults) => {
        this.props.wrapper.hide();
        this.setState({}, ()=> {
            if(dialogResults === undefined || dialogResults === null || dialogResults === []) {
                if(this.props.wrapper.onCancel!==undefined) this.props.wrapper.onCancel();
            } else if (dialogResults.length === 0) {
                if(this.props.wrapper.onCancel!==undefined) this.props.wrapper.onCancel();
            } else {
                if(this.props.wrapper.onConfirm!==undefined) this.props.wrapper.onConfirm(dialogResults);
            }
        });
    }
    /**@type {DialogOptions} */
    dialogOptions=null;
    render() {
        if(this.props.wrapper.show === false) return <></>
        switch(this.props.wrapper.dialogType) {
            case this.dialogType.login: 
                this.loginDialog();
                break;
            case this.dialogType.alert:
                this.alert();
                break;
            case this.dialogType.yesNoPrompt:
                this.yesNoPrompt();
                break;
            case this.dialogType.ffa:
                this.ffa();
                break;
            case this.dialogType.inputbox:
                this.inputBox();
                break;
            case this.dialogType.keyValue:
                this.keyValueDialog();
                break;
            case this.dialogType.change:
                this.changePassword();
                break;
            case this.dialogType.file:
                this.file();
                break;
            default:
                return <></>
        }
        return (
            <Dialog options={this.dialogOptions} show={this.state.showDialog}></Dialog>
        )
    }
}