import React from "react";
import { Link } from "react-router-dom";
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm'
import Macros from "./Macros.js";
/** @typedef {import('./ServerPost').BlogItem} BlogItem */
/** @typedef {import('./ServerPost').Mime } Mime */
export class Preformatter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: "Loading..."
        } 
    }
    componentDidMount = async() => {
        // first time through we might not have an item loaded yet - do nothing
        if(this.props.item === undefined || this.props.item === null ||
           this.props.item.postBody === undefined) return;
        // if here we have something - so load it
        /**@type {BlogItem} */
        var item = { ...this.props.item}; // important - make a copy
        await this.preformatDocument(item);
    }
    /**
     * Pre formats the document for the following items:
     * - Macros
     * - Images / Embeds
     * - Fields
     * @param {BlogItem} item 
     */
    preformatDocument = async(item) => {
        item = await this.preformatMime(item);
        item = await this.preformatMacros(item);
        item = await this.preformatFields(item);
        this.setState({body:item.postBody});
    }
    /**
     * Pulls mime data/images and replaces them inline
     * and also addresses the "width" variable
     * @param {BlogItem} item 
     * @returns {BlogItem} 
     */
    preformatMime = async(item) => {
        var md = new mdImages();
        item = md.preprocessImages(item);
        return item;
    }
    /**
     * Processes inline macros. The following are supported:
     * - {MACRO:listall}: a list of all articles
     * - {MACRO:list(#)}: a list of the most recent # articles, or a search term
     * - {MACRO:recent}: a link to the most recent article
     * - {MACRO:users}: a list of users
     * - {MACROS:users(#)}: a list of users matching search term
     * @param {BlogItem} item 
     * @returns {BlogItem}
     */
    preformatMacros = async(item) => {
        var m = new Macros(this.props.context);
        item.postBody = await m.processMacros(item.postBody);
        return item;
    }
    /**
     * Filters all the body and replaces the fields with the proper values
     * @param {BlogItem} item 
     * @returns {BlogItem}
     */
    preformatFields = async(item) => {
        // no need to process fields if we do not have any
        if(this.props.context.userSettings === undefined || this.props.context.userSettings === null ||
           this.props.context.userSettings.fields === undefined || 
           this.props.context.userSettings.fields === null ||
           this.props.context.userSettings.fields.length === 0) return item;
        // ok - we have fields
        var fields = this.props.context.userSettings.fields;
        for(let key in fields) {
            const p = "{{", s = "}}";
            var field = this.props.context.userSettings.fields[key];
            const searchRegExp = new RegExp(p + field.name + s);
            const replaceWith = field.value;
            item.postBody = item.postBody.replace(searchRegExp, replaceWith);
        }
        return item;
    }
    render() {
        // USES: https://github.com/remarkjs/react-markdown
        // USES: https://github.com/remarkjs/remark-gfm 
        return(
            <ReactMarkdown className="markdownCanvas"
                           children={this.state.body} 
                           components={{a: RouterLink, img: BlogImage}}
                           remarkPlugins={[[remarkGfm, {singleTilde: false}]]} />
        );
    }
}
/**
 * REACT COMPONENT FOR REACT MARKDOWN
 * This class extends react markdown output and allows us to format
 * links the way we want. For one, links that are external will
 * always show a "cloud" icon and open in another window.
 * Next, links that are to the site will use the React Router
 */
export class RouterLink extends React.Component {
    render() {
        if (this.props.href.match(/^(https?:)?\/\//)) {
          // cs-spell:ignore noopener noreferrer
          return (
            <>
                <a href={this.props.href} target="_blank" rel="noopener noreferrer">
                {this.props.children}
                </a><span style={{fontSize:"smaller",verticalAlign:'super'}}>☁</span>
            </>
          );
        }
        return <Link to={this.props.href}>{this.props.children}</Link>;
      }
}
/**
 * REACT COMPONENT FOR REACT MARKDOWN
 * Helps us preprocess images for SIZE markings, specifically to support 
 * the following variations:
 * 
 *    ![tag](https://link,300)
 *    ![tag](https://link,300px)
 *    ![tag](https://link,"300")
 *    ![tag](https://link,width:300)
 *    ![tag](https://link,width:"300px")
 *    ![tag](https://link,"width":"300")
 * 
 * We have ALREADY had to preprocess these, so that the image SIZE is in
 * the TAG property of the image, because we cannot have it in the URL
 * or ReactMarkdown would have already KILLED it as NOT AN IMAGE. So, 
 * we preprocess the above options to:
 * 
 *    ![tag{{props}}](link)
 * 
 * We do this so we can follow some of the various different MD
 * implementations out there that support the ", size" property at the end
 * of the URL string. The reason we fall out of common mark is  because
 * for a blog, we want easy...
 * And the reason we do not use ![tag](image){props} is because the
 * ReactMarkdown object does not give us access to pre/post tags and the
 * {props} tag will not appear in our processing directives.
 * IT IS UGLY... but we have to keep an eye on things with the repo
 *               to see if there is every any updates that help us
 */
export class BlogImage extends React.Component {
    render() {
        var style = { maxWidth:"100%" };
        var alt = this.props.node.properties.alt;
        try {
            var regex = /{{?.*}}/g;
            /** @type {String} */
            var imgProps = alt.match(regex);
            if(imgProps !== null && imgProps.length > 0) {
                var preJson = imgProps[0].replace("{{", "").replace("}}","");
                /**@type {String[]} */
                var parts = preJson.split(":");
                var val = "100%";
                if(parts.length === 1) {
                    // HERE: we have something like {{600}} or {{22%}} or {{600px}} or {{"600px"}}, etc.
                    val = preJson.replace(/"/g,"").replace(/'/,"").trim();
                } else {
                    // HERE: we have some variation of {{width:XXX}} or {{"width":XXX}} or {{"width":"XXX"}}
                    val = parts[1].replace(/"/g,"").replace(/'/,"").trim();
                }
                if(val.indexOf("%")<=0 && val.indexOf("px")<=0) val+="px"; // assume pixels
                preJson = "{\"width\":\"" + val + "\"}";
                style = JSON.parse(preJson);
                alt = alt.replace(imgProps[0],"");
            }
        } catch {}
        return <img {...this.props} alt={alt} style={style} />
    }
}

/**
 * Helper class for md inline images
 */
class mdImages {
    /**
     * 
     * @param {BlogItem} item 
     * @return {BlogItem}
     */
    preprocessImages = (item) => {
        item = this.#processWidth(item);
        item = this.#processMime(item);
        return item;
    }
    /**
     * Process width styles on each image in the blog item
     * @param {BlogItem} item 
     * @return {BlogItem}
     */
    #processWidth = (item) => {
        // we have an an image that looks like this:
        //      ![TAG](url, width: 200)
        // we need to remove the [, width: 200] from the url spec and
        // temporarily add it to the TAG. This is because the 
        // ReactMarkdown component does not support this.
        var regex = /!\[(.*)\]\((.*)\)/g;
        /**@type {RegExpMatchArray} */
        var imageMatches = item.postBody.match(regex);
        if(imageMatches === undefined || imageMatches === null || imageMatches.length === 0) return item; // skip
        for(var imageMatchCnt =0;imageMatchCnt<imageMatches.length;imageMatchCnt++){
            regex = /,(.*)\)/g;
            var widthStyle = imageMatches[imageMatchCnt].match(regex)
            if(widthStyle !== undefined && widthStyle !== null && widthStyle.length > 0) {
                // clean the closing paren
                widthStyle = widthStyle[0].replace(")","");
                // remove the width param from the URL part of the image
                item.postBody = item.postBody.replace(widthStyle, "");
                // now clean the width style more, moving the comma
                widthStyle = widthStyle.replace(",","").trim();
            } else {
                widthStyle = "maxWidth: 100%"; // default value
            }
            // get the TAG of the image ![TAG](url)
            regex = /\[(.*)\]/g;
            var tagBody = imageMatches[imageMatchCnt].match(regex)[0].replace("]","");
            item.postBody = item.postBody.replace(tagBody, tagBody + " {{" + widthStyle + "}}");
        }
        return item;
    }
    /**
     * Process the embeds in the blog item
     * @param {BlogItem} item 
     * @return {BlogItem}
     */
    #processMime = (item) => {
        if(item.mime === undefined || item.mime === null || item.mime.length === 0) return item; // no images  
        for(var mimeCnt=0;mimeCnt<item.mime.length;mimeCnt++) {
            var pattern = "(EMBED:{" + item.mime[mimeCnt].id + "})"; 
            /**@type {RegExpMatchArray} */
            var instances = item.postBody.match(pattern);
            if(instances !== undefined && instances !== null && instances.length > 0) {
                for(var i=0;i<instances.length;i++) {
                    item.postBody = item.postBody.replace(instances[i], item.mime[mimeCnt].data);
                }
            }
        }
        return item;
    }
}