import React from 'react';
import ServerPost, {ServerResponseType} from './ServerPost';
import Captcha from "./Captcha";
import { CommonDialogWrapper } from './CommonDialogWrapper';
import ResetPassword from './ResetPassword';
import ProfileInfo from './ProfileInfo';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
/** @typedef {import('./ServerPost').ServerResponse} ServerResponse */
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
var username = "";
var password = "";
var code = "";
var guid = "";
var context = {};
export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state= {
            needsHuman: false,
            reset: false,
            profile: false,
            noUser: false
        }
    }
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this); 
    /**
     * Logs in the to server with an existing token we get from the
     * session storage or from a new username password prompt
     */
    componentDidMount = async() => {
        // if there is no user, we cannot do the captcha at the start
        if(this.props.context.user === undefined || this.props.context.user === null || this.props.context.user === "") {
            context = {"serverurl" : this.props.context.serverSettings.serverurl, "user": null };
            this.setState({needsHuman: false, noUser: true});
            return;
        }
        context = this.props.context;
        /**@type {ServerPost} */
        var sp = new ServerPost(context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("auth", null, true);
        if(response.type === ServerResponseType.success) {
            // this means our token is still valid      
            this.props.context.sessionToken = response.message;
            this.props.onLogin();
        } else {
            // causes the captcha to appear
            var needed = this.props.forceCaptcha !== undefined ? this.props.forceCaptcha : true; // default is true
            this.setState({needsHuman : needed});
            if(needed === false) this.humanConfirmed();
        }
    }
    /**
     * User clicked cancel
     */
    cancel = () => {
        if(this.props.onCancel !== undefined) this.props.onCancel();
    }
    /**
     * When the page firsts loads we have a captcha if needed
     * and when user confirms, then we pop up login
     */
    humanConfirmed = (guidResponse) => {
        guid = guidResponse; // captcha returns the guid
        this.setState({needsHuman:false, noUser: false}, ()=> {
            this.dialogWrapper.showLogin(this.login, this.cancel, this.reset);
        });
    }
    /**
     * The user, or the BOT failed to properly identify
     * the CAPTCHA so we show an alert and exitS
     */
    botConfirmed = () => {
        this.dialogWrapper.showAlert("Error","Verification failed.", this.cancel);
    }
    /**
     * The user requested to change their password.
     */
    reset = () => {
        this.setState({reset: true});
    }
    /**
     * Send an ACK to the server to get a GUID, then we submit the
     * username and password and finally we get back the token from 
     * the server
     * @param {String[]} dlgResponse 
     */
    login = async(dlgResponse) => {
        username = dlgResponse[0].toLowerCase(); // MUST - because we md5 hash
        password = dlgResponse[1];
        /**@type {ServerPost} */
        var sp = new ServerPost(context);
        var response = await sp.sendCommand("login", null, true, username, password, false, guid);
        if(response.type === ServerResponseType.success) {
            if(this.props.context.user === undefined || this.props.context.user === null || this.props.context.user === "") {
                context = {"serverurl" : this.props.context.serverSettings.serverurl, "user": response.message.user };
                guid = guid.guid; // also update the guid at this point
            }
            this.dialogWrapper.showFFA(this.confirm, this.cancel);
        } else {
            this.dialogWrapper.showAlert("Login Failed", response.message, this.cancel);
        }
    }
    /**
     * After the user hits login, the server will send a 2FA we then
     * get that code from the user here and confirm it with the
     * server and if all is good we store the token
     */
    confirm = async(dlgResponse) => {
        code = dlgResponse[0].toUpperCase().trim(); // because it might come to us lower case
        // confirm our 2fa code
        /**@type {ServerPost} */
        var sp = new ServerPost(context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("2fa", false, true, null, code, false, guid);
        if(response.type === ServerResponseType.success) {
            // logged in -- save the token
            this.props.context.sessionToken = response.message;
            // cs-spell:ignore signintoken signintokenguid
            sessionStorage.setItem("signintoken", response.message);
            sessionStorage.setItem("signintokenguid", guid);
            sessionStorage.setItem("loggedInAs", context.user);
            // we show the user the logon success then go get profile info to make sure
            // the user still is meeting quota space requirements
            this.dialogWrapper.showAlert("Login Success", "You have successfully signed in.", 
                                            ()=>this.setState({profile:true}));
        } else {
            this.dialogWrapper.showAlert("Login Failed", "Two-factor authentication failed.", this.cancel);
        }
    }
    /**
     * Returns the component HTML
     * @returns {HTMLElement}
     */
    render() { 
        return ( 
            <>
                {this.state.needsHuman ? 
                    <Captcha context={this.props.context}
                             onFail={()=>this.botConfirmed()} 
                             onConfirm={(g)=>this.humanConfirmed(g)}
                             noUser={false} />
                : <></> }
                {this.state.noUser ? 
                    <Captcha context={this.props.context}
                             onFail={()=>this.botConfirmed()} 
                             onConfirm={(g)=>this.humanConfirmed(g)}
                             noUser={true} />
                : <></> }
                {this.state.reset === true ?
                    <ResetPassword context={this.props.context} />
                : <></> }
                {this.state.profile === true ?
                    <ProfileInfo context={context} hideUI={true} loadComplete={()=>{
                        var user = this.props.context.user === undefined || this.props.context.user === null || this.props.context.user === "" ? context.user: null;
                        this.props.onLogin(user);
                    }}/>
                :<></> }
                {this.dialogWrapper.renderDialogElement()}
            </> 
        )}
}
export default Login;