import React from 'react'; 
import { useParams } from "react-router";
import { Header } from './Header';
import { Footer } from './Footer';
import './_default.css';
import { ContextUserSettingType } from './AppContext.js';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
/**
 * @typedef {Object} PageParams
 * @property {Context} contextObject
 * @returns 
 */
/**
 * {React.Function}
 * This function evaluates the incoming page request from the router
 * and then loads the proper page from the server
 * @param { PageParams } x
 * @returns 
 */
export function Page( { contextObject, children } ) {
    const { user, page } = useParams();
    contextObject.page = page === undefined ? "home" : page;
    contextObject.user = user;
    contextObject.pageTitle = "";
    if(contextObject.userSettings !== null) {
        document.documentElement.style.setProperty('--textColor', contextObject.getUserSetting(ContextUserSettingType.styles, "textColor"));
        document.documentElement.style.setProperty('--bgColor', contextObject.getUserSetting(ContextUserSettingType.styles, "bgColor"));
        document.documentElement.style.setProperty('--headerBgColor', contextObject.getUserSetting(ContextUserSettingType.styles, "headerBgColor"));
        document.documentElement.style.setProperty('--headerTextColor', contextObject.getUserSetting(ContextUserSettingType.styles, "headerTextColor"));
        document.documentElement.style.setProperty('--fonts', contextObject.getUserSetting(ContextUserSettingType.styles, "fonts"));
    }
    return (
        <div key={new Date().getTime()}> 
            <Header context={contextObject}></Header>
            <div id="bodyContainer" className="reactMarkDown">
                {children}
                <Footer context={contextObject}></Footer>
            </div>
        </div> 
    );
}
export default Page;