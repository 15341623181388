import React from 'react';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
// uses: https://www.npmjs.com/package/emoji-mart
export class Emoji extends React.Component {
    render() {
        return (
            <div>
                <Picker title={""} showPreview={false} onSelect={this.props.emojiSelected} />
            </div>
        );
    }
}

export default Emoji;