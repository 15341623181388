/**
 * @typedef UndoStack
 * This is a class that handles pure text for an undo stack
 */
 export default class UndoStack {
    /** 
     * @constructor
     *  @param {string} text - initial text value for the undo stack
     */
    constructor (text) {
        this.stack = [];
        this.current = -1;
        this.previousState = null;
        if(text !== undefined && text !== null & text !== "") this.push(text);
    }
    /**
     * Push a new text value onto the stack
     * @param {string} text 
     */
    push = (text) => {
        if(this.previousState !== text) {
            this.previousState = text;
            this.current++;
            this.stack.splice(this.current);
            this.stack.push(text);
        }
    }
    /**
     * Returns the last item on the undo stack
     * @returns {string} the last item on the undo stack
     */
    undo = () => {
        try {
            var text;
            if (this.current >= 0) {
                text = this.stack[this.current];
                this.current--;
                return text;
            }
        } catch { }
        return null;
    }
}