import React from 'react';
import TwoFactor from './TwoFactor.js';
import ServerPost, { ServerResponseType } from './ServerPost.js';
import CommonDialogWrapper from './CommonDialogWrapper';
import LogOut from './LogOut.js';
/** @typedef {import('./CommonDialogWrapper.js').CommonDialogWrapper} CommonDialogWrapper */
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
export class ChangeEmail extends React.Component {
    /**@type {CommonDialogWrapper} */
    dialogWrapper = new CommonDialogWrapper(this);
    constructor(props) {
        super(props);
        this.state = {
            locked: true,
            confirmationStep: 0,
            newEmail: "",
        }
    }
    sendToNewEmail = async() => {
        /**@type {ServerPost} */
        var sp = new ServerPost(this.props.context);
        /**@type {ServerResponse} */                                // MUST toLowerCase() - because we md5 hash
        var response = await sp.sendCommand("newemail", null, true, this.state.newEmail.toLowerCase(), null, true); 
        if(response.type === ServerResponseType.success) {
            this.setState( { confirmationStep: 2} );
        } else {
            this.dialogWrapper.showAlert("Error", "Unable to complete the request. " + response.message, this.reset);
        }
    }
    changeEmail = async() => {
        this.dialogWrapper.showInputbox("Confirm Email", "Please enter the confirmation code sent to your email.", "",
                                        this.verifyCode,
                                        this.reset);
    }
    reset = () => {
        this.props.context.userSettings.emailVerified = true;
        this.setState({locked:true, confirmationStep:0});
    }
    ffaFailed = () => {
        this.dialogWrapper.showAlert("Error", "The two-factor code was not properly verified.", this.reset);
    }
    verifyCode = async(dlgResponse) => {
        var code = dlgResponse[0];
        // confirm our 2fa code
        /**@type {ServerPost} */
        var sp = new ServerPost(this.props.context);
        /**@type {ServerResponse} */
        var response = await sp.sendCommand("emailconfirmed", null, true, null, code);
        if(response.type === ServerResponseType.success) {
            this.setState({ "confirmationStep": 3 }); 
            // now we need to tell the context to update
            this.props.context.update();          
        } else {
            this.dialogWrapper.showAlert("Error", "The email change failed.", this.reset);     
        }                 
    }
    render() {
        var thisRef = this;
        switch(this.state.confirmationStep)
        {
            case 0:
                return (
                    <>
                        <h3>Email Address</h3>
                        <span>
                            Your email address is used for 2-factor authentication. You will be required to validate
                            your email address if you change it to verify that it is correct.
                        </span> <br/><br/>
                        <span>Your email address:</span>{'  '}<input disabled={this.state.locked} type="text" defaultValue={this.props.context.userSettings.email}
                                                                    onChange={(e)=>{
                                                                        // MUST toLowerCase() Email - because we md5 hash
                                                                        if(e.target.value.toLowerCase() !== thisRef.props.context.userSettings.email.toLowerCase()) {
                                                                            thisRef.props.context.userSettings.emailVerified = false;
                                                                            thisRef.setState({newEmail:e.target.value.toLowerCase()});
                                                                        } else {
                                                                            thisRef.props.context.userSettings.emailVerified = true;
                                                                            thisRef.setState({});
                                                                        }
                                                                    }}/>
                        <span>{this.props.context.userSettings.emailVerified?"✔️ (verified)":"❌ (not verified)"}</span>
                        <span>{'   '}<button disabled={!this.state.locked} onClick={(e)=>this.setState({"locked": false})}>Change</button></span>
                        {!this.state.locked ? (
                                <span>{'   '}<button disabled={this.state.locked} onClick={() => this.setState( {confirmationStep: 1})}>Confirm</button></span>
                            ) : (
                                <></>
                            ) 
                        }
                    </>
                );
            case 1:
                return (
                    <>
                        <h3>Email Address</h3>
                        <span>You must first confirm your original email...</span>
                        <TwoFactor context={this.props.context} 
                            confirmAndPrompt={true} 
                            confirmed={this.sendToNewEmail}
                            cancelled={this.ffaFailed}>
                        </TwoFactor>
                        {this.dialogWrapper.renderDialogElement()}
                    </>
                );
            case 2:
                return (
                    <>
                        <h3>Email Address</h3>
                        <span>
                            <p>An email was sent to your new email address: ({this.state.newEmail})</p>
                            <p>Please click confirm and enter the code sent to your new email address to complete the change.</p>
                        </span>
                        <button onClick={this.changeEmail}>Confirm...</button>
                        {this.dialogWrapper.renderDialogElement()}
                    </>
                )
            case 3: 
                return (
                <>
                        <h3>Email Address</h3>
                        <span>
                            <p>Your email address was successfully changed to: ({this.state.newEmail})</p>
                            <p>It is recommended that you now log out and log in again with your new email.</p>
                            <button onClick={()=>this.setState({confirmationStep:4})}>Logout</button>
                        </span>
                </>
            )
            case 4:
                return (<LogOut onCancel={()=>this.setState({confirmationStep:3})} />);
            default:
                return <></>
        }
    }
}
export default ChangeEmail;