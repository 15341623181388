import React from 'react';
import './_default.css';
import { ContextUserSettingType } from './AppContext.js';
/** @typedef {import('./AppContext.js').Context} Context */
/** @typedef {import('./AppContext.js').ContextUserSettingType} ContextUserSettingType */
export class Footer extends React.Component {
    render() {
        /**@type {Context} */
        var appContext = this.props.context;
        return (    
            <div className="Footer">
                <hr/>
                <p>{appContext.getUserSetting(ContextUserSettingType.fields,"copyright")}</p>
            </div>
        );
    }
}
export default Footer;
